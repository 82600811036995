<ng-container
    *ngrxLet="{
        courseViewData: viewData$,
        isLoading: isLoading$,
        courseDescription: courseDescription$,
        quizFormGroup: quizFormGroup$,
        isMobile: isMobile$,
        isMenuOpen: isMenuOpen$
    } as vm"
>
    <article class="course-content" *ngIf="vm.courseViewData?.currentUnit">
        <header class="header">
            <div class="header-text">
                <h2>{{ vm.courseViewData.name }}</h2>
            </div>
            <div class="header-image">
                <img class="icon" alt="" [src]="vm.courseViewData.tags | invoke : getTagIcon : true" />
            </div>
        </header>
        <aside class="menu" *ngrxLet="activeTab$ as activeTab" [class.open]="isMenuOpen$ | ngrxPush">
            <div class="menu-controls">
                <button type="button" (click)="toggleMenu()" aria-label="Toggle details" class="button-icon">
                    <dtm-ui-icon name="close"></dtm-ui-icon>
                </button>
            </div>
            <div class="menu-container">
                <div class="tabs">
                    <div class="tabs-header">
                        <div
                            [class.active]="activeTab === CourseProgressPageMenuTab.Progress"
                            (click)="setActiveTab(CourseProgressPageMenuTab.Progress)"
                            (keydown.enter)="setActiveTab(CourseProgressPageMenuTab.Progress)"
                            role="button"
                            tabindex="0"
                        >
                            {{ "eLearningLib.courseProgressPage.progressTabLabel" | transloco }}
                        </div>
                        <div
                            [class.active]="activeTab === CourseProgressPageMenuTab.Description"
                            (click)="setActiveTab(CourseProgressPageMenuTab.Description)"
                            (keydown.enter)="setActiveTab(CourseProgressPageMenuTab.Description)"
                            role="button"
                            tabindex="0"
                        >
                            {{ "eLearningLib.courseProgressPage.descriptionTabLabel" | transloco }}
                        </div>
                    </div>

                    <div class="tabs-content" [attr.inert]="(vm.isMobile && !vm.isMenuOpen) || null">
                        <nav class="menu-content" [class.hidden]="activeTab === CourseProgressPageMenuTab.Progress">
                            <h4>{{ vm.courseViewData.description.header }}</h4>
                            <ng-container *ngIf="vm.courseDescription; else loadingCourseDescriptionTemplate">
                                <p [innerHTML]="vm.courseDescription | eLearningBypassSanitizer"></p>
                            </ng-container>
                            <ng-template #loadingCourseDescriptionTemplate>
                                <mat-spinner diameter="30" color="accent"></mat-spinner>
                            </ng-template>
                        </nav>

                        <div class="menu-content" [class.hidden]="activeTab === CourseProgressPageMenuTab.Description">
                            <div class="progress-container" *ngIf="vm.courseViewData.progress">
                                <span class="progress-label">
                                    {{
                                        "eLearningLib.courseProgressPage.progressBarLabel"
                                            | transloco : { value: vm.courseViewData.progress.completionPercentage | number : "1.0-0" }
                                    }}
                                </span>
                                <mat-progress-bar
                                    mode="determinate"
                                    [value]="vm.courseViewData.progress.completionPercentage"
                                ></mat-progress-bar>
                            </div>

                            <ng-container *ngFor="let chapter of vm.courseViewData.chapters">
                                <h5>{{ chapter.title }}</h5>
                                <ul>
                                    <li *ngFor="let unit of chapter.units" [class]="'unit-status-' + unit.status">
                                        <a [routerLink]="unit.link">{{ unit.title }}</a>
                                    </li>
                                </ul>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </aside>
        <section
            class="content"
            [ngSwitch]="vm.courseViewData.currentUnit.type"
            [class]="'unit-type-' + vm.courseViewData.currentUnit.type"
        >
            <ng-container *ngSwitchCase="UnitType.Lesson">
                <hgroup>
                    <p>{{ vm.courseViewData.currentUnit.chapterTitle }}</p>
                    <h3>{{ vm.courseViewData.currentUnit.title }}</h3>
                </hgroup>

                <p
                    [innerHTML]="vm.courseViewData.currentUnit.textHtml | eLearningBypassSanitizer"
                    *ngIf="vm.courseViewData.currentUnit.type === UnitType.Lesson"
                ></p>
                <div class="course-buttons">
                    <button
                        type="button"
                        class="button-secondary"
                        [routerLink]="vm.courseViewData.previousLink"
                        [disabled]="vm.isLoading"
                        *ngIf="vm.courseViewData.previousLink"
                    >
                        <dtm-ui-icon name="arrow-go-back"></dtm-ui-icon>
                        {{ "eLearningLib.courseProgressPage.previousUnitButtonLabel" | transloco }}
                    </button>
                    <button type="button" class="button-primary" [routerLink]="vm.courseViewData.nextLink" [disabled]="vm.isLoading">
                        {{
                            "eLearningLib.courseProgressPage.nextUnitOrFinishCourseButtonLabel"
                                | transloco : { isNextLinkSummary: vm.courseViewData.isNextLinkSummary }
                        }}
                        <dtm-ui-icon name="arrow-go"></dtm-ui-icon>
                    </button>
                </div>
            </ng-container>

            <ng-container *ngSwitchCase="UnitType.ExamDescription">
                <hgroup>
                    <p>{{ vm.courseViewData.currentUnit.chapterTitle }}</p>
                    <h3>{{ vm.courseViewData.currentUnit.title }}</h3>
                </hgroup>

                <p
                    [innerHTML]="vm.courseViewData.currentUnit.textHtml | eLearningBypassSanitizer"
                    *ngIf="vm.courseViewData.currentUnit.type === UnitType.ExamDescription"
                ></p>
                <button type="button" class="button-primary" [disabled]="vm.isLoading" (click)="startExam(vm.courseViewData.nextLink)">
                    {{ "eLearningLib.courseProgressPage.startExamButtonLabel" | transloco }}
                </button>
            </ng-container>

            <ng-container *ngSwitchCase="UnitType.Quiz">
                <ng-container
                    [ngTemplateOutlet]="quizHeaderWithNoteTemplate"
                    [ngTemplateOutletContext]="{
                        title: vm.courseViewData.currentUnit.title,
                        chapterTitle:
                            'eLearningLib.courseProgressPage.quizDescription'
                            | transloco : { chapterTitle: vm.courseViewData.currentUnit.chapterTitle }
                    }"
                ></ng-container>

                <ng-template [ngTemplateOutlet]="quizTemplate"></ng-template>
            </ng-container>

            <ng-container *ngSwitchCase="UnitType.QuizResults">
                <ng-container
                    [ngTemplateOutlet]="quizHeaderWithNoteTemplate"
                    [ngTemplateOutletContext]="{
                        title: vm.courseViewData.currentUnit.title,
                        chapterTitle:
                            'eLearningLib.courseProgressPage.quizDescription'
                            | transloco : { chapterTitle: vm.courseViewData.currentUnit.chapterTitle }
                    }"
                ></ng-container>
                <ng-template [ngTemplateOutlet]="quizTemplate"></ng-template>
            </ng-container>
        </section>
    </article>

    <ng-template #quizTemplate>
        <ol
            *ngIf="
                (vm.courseViewData.currentUnit.type === UnitType.Quiz || vm.courseViewData.currentUnit.type === UnitType.QuizResults) &&
                vm.quizFormGroup
            "
        >
            <li *ngFor="let question of vm.courseViewData.currentUnit.quizData">
                <span class="question" [innerHTML]="question.questionTextHtml | eLearningBypassSanitizer"></span>
                <form class="answers" [formGroup]="vm.quizFormGroup.controls[question.id]">
                    <elearning-lib-question-answer
                        *ngFor="let answer of question.answers"
                        [type]="question.isMultipleChoice ? QuestionAnswerType.Checkbox : QuestionAnswerType.Radio"
                        [formControlName]="answer.id"
                        [isCorrect]="answer.isCorrect"
                        [isReadonly]="vm.courseViewData.currentUnit.type === UnitType.QuizResults"
                    >
                        <span [innerHTML]="answer.textHtml | eLearningBypassSanitizer"></span>
                    </elearning-lib-question-answer>
                </form>
            </li>
        </ol>
        <ng-container *ngIf="vm.courseViewData.currentUnit.type === UnitType.QuizResults; else endQuizButtonTemplate">
            <elearning-lib-quiz-result
                [hasPassed]="vm.courseViewData.currentUnit.hasPassed"
                [percentage]="vm.courseViewData.currentUnit.validityPercentage"
            >
            </elearning-lib-quiz-result>
            <div class="course-buttons">
                <button
                    type="button"
                    class="button-secondary"
                    [routerLink]="vm.courseViewData.previousLink"
                    [disabled]="vm.isLoading"
                    *ngIf="vm.courseViewData.previousLink"
                >
                    <dtm-ui-icon name="arrow-go-back"></dtm-ui-icon>
                    {{ "eLearningLib.courseProgressPage.previousUnitButtonLabel" | transloco }}
                </button>
                <button type="button" class="button-primary" [routerLink]="vm.courseViewData.nextLink" [disabled]="vm.isLoading">
                    {{
                        "eLearningLib.courseProgressPage.nextUnitOrFinishCourseButtonLabel"
                            | transloco : { isNextLinkSummary: vm.courseViewData.isNextLinkSummary }
                    }}
                </button>
            </div>
        </ng-container>
        <ng-template #endQuizButtonTemplate>
            <button
                type="button"
                class="button-primary"
                (click)="submitQuizAnswers(vm.courseViewData.nextLink, vm.quizFormGroup?.value)"
                [disabled]="vm.isLoading || vm.quizFormGroup?.valid !== true"
            >
                {{ "eLearningLib.courseProgressPage.endQuizButtonLabel" | transloco }}
            </button>
        </ng-template>
    </ng-template>
</ng-container>

<ng-template #quizHeaderWithNoteTemplate let-title="title" let-chapterTitle="chapterTitle">
    <hgroup>
        <h3>{{ title }}</h3>
        <p>{{ chapterTitle }}</p>
    </hgroup>
    <div class="elearning-course-card">
        <section class="infobox-red" [attr.data-infobox-header]="'eLearningLib.courseProgressPage.quizNoteLabel' | transloco">
            {{ "eLearningLib.courseProgressPage.quizNoteValue" | transloco }}
        </section>
    </div>
</ng-template>
