import { SoundType } from "../models/sounds.models";

export namespace SoundsActions {
    export class PlaySound {
        public static readonly type = "[SUP - Sounds] Play sound";
        constructor(public soundType: SoundType) {}
    }

    export class StopSound {
        public static readonly type = "[SUP - Sounds] Stop sound";
        constructor(public soundType: SoundType) {}
    }

    export class SoundPlayError {
        public static readonly type = "[SUP - Sounds] Sound play error";
        constructor(public soundType: SoundType, public error: unknown) {}
    }
}
