import { ChangeDetectionStrategy, Component, ElementRef, forwardRef, Input, OnInit, ViewChild } from "@angular/core";
import {
    AbstractControl,
    ControlValueAccessor,
    FormControl,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
} from "@angular/forms";
import { FunctionUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import equal from "fast-deep-equal";
import { distinctUntilChanged, startWith } from "rxjs/operators";
import listOfCountryNames from "../../../assets/countries/countries.json";
import alpha2to3Map from "../../../assets/countries/countries2to3.json";
import { Alpha2CountryCode, Alpha3CountryCode, CountryListItem } from "../../models/country.models";
import { SelectFieldComponent } from "../select-field/select-field.component";

interface CountrySelectFieldComponentState {
    selectedCountry: CountryListItem | undefined;
    searchPlaceholder: string | undefined;
    suggestedValue: Alpha3CountryCode | undefined;
}

@UntilDestroy()
@Component({
    selector: "dtm-ui-country-select-field",
    templateUrl: "./country-select-field.component.html",
    styleUrls: ["./country-select-field.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CountrySelectFieldComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => CountrySelectFieldComponent),
            multi: true,
        },
    ],
})
export class CountrySelectFieldComponent implements OnInit, ControlValueAccessor, Validator {
    @Input() public set searchPlaceholder(value: string | undefined) {
        this.localStore.patchState({ searchPlaceholder: value });
    }

    @Input() public set suggestedValue(value: Alpha3CountryCode | undefined) {
        this.localStore.patchState({ suggestedValue: value });
    }

    @ViewChild("search") public search!: ElementRef;
    @ViewChild("selectTemplate") public select!: SelectFieldComponent;

    protected countryFormControl = new FormControl<Alpha3CountryCode | null>(null);

    protected readonly searchControl = new FormControl<string>("", { nonNullable: true });
    protected readonly searchPlaceholder$ = this.localStore.selectByKey("searchPlaceholder");
    protected readonly selectedCountry$ = this.localStore.selectByKey("selectedCountry");
    protected listOfCountriesDisplay!: CountryListItem[];
    protected readonly defaultListOfCountries: CountryListItem[] = Object.entries(listOfCountryNames)
        .map(([countryCode, [countryInternationalName, countryNativeName]]): CountryListItem => {
            const displayName = countryNativeName ? `${countryInternationalName} (${countryNativeName})` : countryInternationalName;

            return {
                alpha2Code: countryCode as Alpha2CountryCode,
                alpha3Code: alpha2to3Map[countryCode as Alpha2CountryCode],
                displayName,
            };
        })
        .sort(this.sortCountries);

    private propagateTouch = FunctionUtils.noop;
    private propagateChange: (value: Alpha3CountryCode | null) => void = FunctionUtils.noop;
    private onValidationChange = FunctionUtils.noop;

    constructor(private readonly localStore: LocalComponentStore<CountrySelectFieldComponentState>) {
        localStore.setState({
            selectedCountry: undefined,
            searchPlaceholder: undefined,
            suggestedValue: undefined,
        });
    }

    public ngOnInit() {
        this.countryFormControl.valueChanges.pipe(distinctUntilChanged(equal), untilDestroyed(this)).subscribe((value) => {
            this.propagateChange(value);
            this.assignSelectedCountry(value);
            this.searchControl.reset("");
        });

        this.searchControl.valueChanges.pipe(startWith(""), untilDestroyed(this)).subscribe((searchValue) => {
            const suggestedValue = this.localStore.selectSnapshotByKey("suggestedValue");

            if (suggestedValue) {
                this.listOfCountriesDisplay = this.defaultListOfCountries.reduce<CountryListItem[]>((result, country) => {
                    if (country.displayName.toLowerCase().includes(searchValue.toLowerCase())) {
                        return suggestedValue === country.alpha3Code ? [country, ...result] : [...result, country];
                    }

                    return result;
                }, []);
            } else {
                this.listOfCountriesDisplay = this.defaultListOfCountries.filter((country) =>
                    country.displayName.toLowerCase().includes(searchValue.toLowerCase())
                );
            }
        });
    }

    public registerOnChange(fn: (value: Alpha3CountryCode | null) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    public registerOnValidatorChange(fn: () => void): void {
        this.onValidationChange = fn;
    }

    public validate(control: AbstractControl): ValidationErrors | null {
        return control.valid ? null : control.errors;
    }

    public writeValue(value: Alpha3CountryCode | null): void {
        if (value) {
            this.countryFormControl.setValue(value);
            this.assignSelectedCountry(value);

            if (!this.validate(this.countryFormControl)) {
                this.propagateChange(value);
            }
            this.onValidationChange();
        } else {
            this.countryFormControl.reset();
        }
    }

    public setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.countryFormControl.disable();
        } else {
            this.countryFormControl.enable();
        }
    }

    protected setControlValueFromSearch() {
        const activeOption = this.select.options.find((option) => option.active);
        if (activeOption) {
            this.countryFormControl.setValue(activeOption.value);

            return;
        }

        if (!this.listOfCountriesDisplay.length) {
            return;
        }

        this.countryFormControl.setValue(this.listOfCountriesDisplay[0].alpha3Code);
    }

    protected countryListItemTrack(_: number, item: CountryListItem): Alpha3CountryCode {
        return item.alpha3Code;
    }

    protected keyDown(event: KeyboardEvent): void {
        if (event.key === "Tab") {
            return;
        }
        event.stopPropagation();
        this.search.nativeElement.focus();
    }

    private sortCountries(rightCountry: CountryListItem, leftCountry: CountryListItem) {
        const rightCountryCompare = rightCountry.displayName.toLowerCase();
        const leftCountryCompare = leftCountry.displayName.toLowerCase();

        return new Intl.Collator().compare(rightCountryCompare ?? "", leftCountryCompare ?? "");
    }

    private assignSelectedCountry(value: Alpha3CountryCode | null) {
        this.localStore.patchState({
            selectedCountry: this.defaultListOfCountries.find((country) => country.alpha3Code === value),
        });
    }
}
