import { TranslocoService } from "@jsverse/transloco";
import { QuillConfig } from "ngx-quill";
import Quill from "quill";
import { defer } from "rxjs";

const Block = Quill.import("blots/block");
const Break = Quill.import("blots/break");
const Embed = Quill.import("blots/embed");
const Delta = Quill.import("delta");

function lineBreakMatcher() {
    const newDelta = new Delta();
    newDelta.insert({ break: "" });

    return newDelta;
}

function registerCustomBlots(translocoService: TranslocoService) {
    class InfoBoxRedBlot extends Block {
        public static create(value: any) {
            const node = super.create(value);
            node.setAttribute("data-infobox-header", translocoService.translate("dtmUi.adminPanel.warningLabel"));

            return node;
        }
    }
    InfoBoxRedBlot.blotName = "infobox-red";
    InfoBoxRedBlot.tagName = "section";
    InfoBoxRedBlot.className = "infobox-red";

    class InfoBoxBlueBlot extends Block {
        public static create(value: any) {
            const node = super.create(value);
            node.setAttribute("data-infobox-header", translocoService.translate("dtmUi.adminPanel.triviaLabel"));

            return node;
        }
    }
    InfoBoxBlueBlot.blotName = "infobox-blue";
    InfoBoxBlueBlot.tagName = "section";
    InfoBoxBlueBlot.className = "infobox-blue";

    class InfoBoxYellowBlot extends Block {
        public static create(value: any) {
            const node = super.create(value);
            node.setAttribute("data-infobox-header", translocoService.translate("dtmUi.adminPanel.rememberLabel"));

            return node;
        }
    }
    InfoBoxYellowBlot.blotName = "infobox-yellow";
    InfoBoxYellowBlot.tagName = "section";
    InfoBoxYellowBlot.className = "infobox-yellow";

    class SmartBreak extends Break {
        public length() {
            return 1;
        }
        public value() {
            return "\n";
        }

        public insertInto(parent: any, ref: any) {
            Embed.prototype.insertInto.call(this, parent, ref);
        }
    }
    SmartBreak.blotName = "break";
    SmartBreak.tagName = "BR";

    Quill.register(InfoBoxBlueBlot);
    Quill.register(InfoBoxYellowBlot);
    Quill.register(InfoBoxRedBlot);
    Quill.register(SmartBreak);
}

export function getQuillConfig(translocoService: TranslocoService): QuillConfig {
    registerCustomBlots(translocoService);

    return {
        modules: {
            clipboard: {
                matchers: [["BR", lineBreakMatcher]],
            },
            keyboard: {
                bindings: {
                    linebreak: {
                        key: 13,
                        shiftKey: true,
                        handler: function (range: { index: number }) {
                            const context = this as any;
                            const currentLeaf = context.quill.getLeaf(range.index)[0];
                            const nextLeaf = context.quill.getLeaf(range.index + 1)[0];
                            context.quill.insertEmbed(range.index, "break", true, "user");

                            if (nextLeaf === null || currentLeaf.parent !== nextLeaf.parent) {
                                context.quill.insertEmbed(range.index, "break", true, "user");
                            }
                            context.quill.setSelection(range.index + 1, Quill.sources.SILENT);
                        },
                    },
                },
            },
            syntax: false,
            blotFormatter: {},
        },
        customModules: [
            {
                path: "modules/blotFormatter",
                implementation: defer(() => import("quill-blot-formatter").then((module) => module.default)),
            },
        ],
    };
}
