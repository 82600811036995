import { DOCUMENT } from "@angular/common";
import { APP_INITIALIZER, Inject, NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from "@angular/material/legacy-progress-spinner";
import { MatLegacyTabsModule } from "@angular/material/legacy-tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BrowserModule, Title } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ELEARNING_ENDPOINTS, ELearningModule, MAIN_PAGE_APP_URL, UAV_APP_URL } from "@dtm-frontend/elearning-client-lib";
import { KEYCLOAK_CONFIG, SharedAuthModule } from "@dtm-frontend/shared/auth";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import {
    I18nRootModule,
    LANGUAGE_CONFIGURATION,
    LanguageCode,
    TranslationHelperService,
    getTranslocoInlineLoader,
} from "@dtm-frontend/shared/ui/i18n";
import { SharedToastModule } from "@dtm-frontend/shared/ui/toast";
import { ACCESSIBILITY_STATEMENT_URL, LOCAL_STORAGE, Logger, LoggerModule, TERMS_OF_USE_URL } from "@dtm-frontend/shared/utils";
import { TRANSLOCO_SCOPE, TranslocoService } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";
import { NgxsStoragePluginModule, StorageOption } from "@ngxs/storage-plugin";
import { NgxsModule, Store } from "@ngxs/store";
import { KeycloakEventType, KeycloakService } from "keycloak-angular";
import { forkJoin } from "rxjs";
import { filter, first, tap } from "rxjs/operators";
import { ELearningClientEnvironment } from "../environments/environment.model";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AdminPanelComponent } from "./components/admin-panel/admin-panel.component";
import { HeaderComponent } from "./components/header/header.component";
import { MobileMenuComponent } from "./components/mobile-menu/mobile-menu.component";
import { NotAuthorizedComponent } from "./components/not-authorized/not-authorized.component";
import { ActionsHandler } from "./services/actions.handler";
import { initialAppSetup } from "./utils/initial-app-setup";

Logger.initialize("https://3327c8773605ea08279f81912d57bce5@sentry.pansa.cloud/7");

export function createAppModule(environment: ELearningClientEnvironment) {
    @NgModule({
        declarations: [AppComponent, HeaderComponent, NotAuthorizedComponent, AdminPanelComponent, MobileMenuComponent],
        imports: [
            NgxsModule.forRoot([], {
                // NOTE: must be first because of https://github.com/ngxs/store/issues/375
                developmentMode: !environment.production,
            }),
            AppRoutingModule,
            BrowserAnimationsModule,
            BrowserModule,
            I18nRootModule.forRoot({
                developmentMode: !environment.production,
                ...LANGUAGE_CONFIGURATION,
            }),
            MatInputModule,
            MatIconModule,
            MatProgressSpinnerModule,
            MatTooltipModule,
            NgxsStoragePluginModule.forRoot({
                key: ["ui"],
                storage: StorageOption.LocalStorage,
            }),
            NgxsReduxDevtoolsPluginModule.forRoot({
                disabled: environment.production,
                name: "UavIdentificationAdmin",
            }),
            LetModule,
            PushModule,
            LoggerModule.forRoot(environment.name, !environment.production),
            SharedAuthModule.forRoot({
                bearerExcludedUrls: [...environment.bearerExcludedUrls],
            }),
            SharedToastModule.forRoot(),
            SharedUiModule,
            ELearningModule,
            MatLegacyTabsModule,
        ],
        providers: [
            {
                provide: APP_INITIALIZER,
                useFactory: initialAppSetup,
                deps: [ActionsHandler, Store, TranslocoService],
                multi: true,
            },
            {
                provide: TRANSLOCO_SCOPE,
                multi: true,
                useValue: {
                    scope: "eLearning",
                    loader: getTranslocoInlineLoader((language: LanguageCode) => import(`../assets/i18n/${language}.json`)),
                },
            },
            {
                provide: ELEARNING_ENDPOINTS,
                useValue: environment.elearningEndpoints,
            },
            { provide: LOCAL_STORAGE, useValue: localStorage },
            {
                provide: KEYCLOAK_CONFIG,
                useValue: environment.keycloakConfig,
            },
            {
                provide: UAV_APP_URL,
                useValue: environment.uavAppUrl,
            },
            {
                provide: MAIN_PAGE_APP_URL,
                useValue: environment.mainPageAppUrl,
            },
            {
                provide: TERMS_OF_USE_URL,
                useValue: environment.elearningEndpoints.termsOfUse,
            },
            {
                provide: ACCESSIBILITY_STATEMENT_URL,
                useValue: environment.elearningEndpoints.accessibilityStatement,
            },
        ],
        bootstrap: [AppComponent],
    })
    class AppModule {
        constructor(
            keycloakService: KeycloakService,
            store: Store,
            translocoHelper: TranslationHelperService,
            titleService: Title,
            @Inject(DOCUMENT) document: Document
        ) {
            // TODO: is this a good place for some initializing tasks? Maybe it should be extracted somewhere, use APP_INITIALIZER?
            const gotUserData$ = keycloakService.keycloakEvents$.pipe(
                filter((event) => event?.type === KeycloakEventType.OnReady),
                first()
            );

            const gotTitle$ = translocoHelper.waitForTranslation("eLearning.appTitle").pipe(
                first(),
                tap((title) => titleService.setTitle(title ?? "-"))
            );

            forkJoin([gotUserData$, gotTitle$])
                .pipe(
                    first(),
                    tap(() => {
                        document.getElementById("loader")?.remove();
                    })
                )
                .subscribe();
        }
    }

    return AppModule;
}
