<div
    *ngrxLet="{ isCorrect: isCorrect$, isSelected: isSelected$, readonly: isReadonly$ } as vm"
    class="answer"
    [class.readonly]="vm.readonly"
    [class.selected]="vm.isSelected"
    [class.correct]="vm.isCorrect === true"
    [class.incorrect]="vm.isCorrect === false"
    [attr.tabindex]="vm.readonly ? -1 : 0"
    (click)="!vm.readonly && toggleSelection()"
    (keydown.enter)="!vm.readonly && toggleSelection()"
    (keydown.space)="$event.preventDefault(); !vm.readonly && toggleSelection()"
>
    <dtm-ui-icon [name]="icon$ | ngrxPush"></dtm-ui-icon>
    <ng-content></ng-content>
</div>
