import { ChangeDetectionStrategy, Component } from "@angular/core";
import { AbstractControl, FormControl, FormGroup, ValidationErrors } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { DeviceSize, DeviceSizeService } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Observable, combineLatest, filter, map, switchMap, tap, withLatestFrom } from "rxjs";
import { QuestionAnswerType } from "../../components/question-answer/question-answer.component";
import { CourseProgressPageViewData, QuizAnswersFormGroupValue, UnitType } from "../../models/elearning.model";
import { getTagIcon } from "../../utils/get-tag-icon";
import { ElearningPageCommonsDirective } from "../elearning-page-commons.directive";

enum CourseProgressPageMenuTab {
    Progress = "Progress",
    Description = "Description",
}

interface CourseProgressPageComponentState {
    activeTab: CourseProgressPageMenuTab;
    courseDescription: string | undefined;
    isMenuOpen: boolean;
}

type QuizFormGroup = FormGroup<{
    [questionId: string]: FormGroup<{
        [answerId: string]: FormControl<boolean>;
    }>;
}>;

export const courseDescriptionProviderResolveKey = "descriptionProvider";
export const startExamNavigationStateParam = "startExam";
export type CourseDescriptionProvider = (descriptionLink: string | undefined) => Observable<string | undefined>;

@UntilDestroy()
@Component({
    templateUrl: "./course-progress-page.component.html",
    styleUrls: ["../../../styles/_elearning-course.scss", "../common-page.styles.scss", "./course-progress-page.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    hostDirectives: [ElearningPageCommonsDirective],
    providers: [LocalComponentStore],
})
export class CourseProgressPageComponent {
    protected readonly UnitType = UnitType;
    protected readonly CourseProgressPageMenuTab = CourseProgressPageMenuTab;
    protected readonly QuestionAnswerType = QuestionAnswerType;
    protected readonly getTagIcon = getTagIcon;

    protected readonly viewData$ = this.route.data.pipe(map(({ viewData }) => viewData as CourseProgressPageViewData));
    protected readonly activeTab$ = this.localStore.selectByKey("activeTab");
    protected readonly isMenuOpen$ = this.localStore.selectByKey("isMenuOpen");
    protected readonly isLoading$ = combineLatest([this.elearningPageCommons.isLoading$, this.viewData$]).pipe(
        map(([isLoading, viewData]) => isLoading || !viewData?.currentUnit)
    );
    protected readonly courseDescription$ = this.localStore.selectByKey("courseDescription");
    protected readonly quizFormGroup$ = this.createQuizFormGroup();
    protected readonly isMobile$ = this.deviceSizeService.getSizeObservable(DeviceSize.Smartphone, DeviceSize.SmartphoneWide);

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly localStore: LocalComponentStore<CourseProgressPageComponentState>,
        private readonly elearningPageCommons: ElearningPageCommonsDirective,
        private readonly deviceSizeService: DeviceSizeService
    ) {
        this.localStore.setState({
            activeTab: CourseProgressPageMenuTab.Progress,
            courseDescription: undefined,
            isMenuOpen: false,
        });

        this.route.data
            .pipe(
                map((data) => {
                    const descriptionProvider = data[courseDescriptionProviderResolveKey] as CourseDescriptionProvider | undefined;
                    const viewData = data.viewData as CourseProgressPageViewData;

                    return { descriptionProvider, viewData };
                }),
                withLatestFrom(this.localStore.selectByKey("courseDescription")),
                filter(
                    ([{ descriptionProvider, viewData }, courseDescription]) =>
                        !!viewData && !!descriptionProvider && courseDescription === undefined
                ),
                switchMap(([{ descriptionProvider, viewData }]) =>
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    descriptionProvider!(viewData.description.descriptionLink).pipe(
                        tap((description) => {
                            this.localStore.patchState({ courseDescription: description });
                        })
                    )
                ),
                untilDestroyed(this)
            )
            .subscribe();
    }

    private static answersGroupValidator(control: AbstractControl): ValidationErrors | null {
        const value = control.value as QuizAnswersFormGroupValue;

        return Object.values(value).some((answersGroup) => answersGroup && Object.values(answersGroup).every((answer) => answer !== true))
            ? { required: true }
            : null;
    }

    protected setActiveTab(activeTab: CourseProgressPageMenuTab) {
        this.localStore.patchState({ activeTab, isMenuOpen: true });
    }

    protected submitQuizAnswers(link: string | undefined, answers: QuizAnswersFormGroupValue | undefined) {
        if (!link || !answers) {
            return;
        }

        this.router.navigate([link], { state: answers });
    }

    protected startExam(link: string | undefined) {
        if (!link) {
            return;
        }

        this.router.navigate([link], { state: { [startExamNavigationStateParam]: true } });
    }

    protected toggleMenu() {
        this.localStore.patchState(({ isMenuOpen }) => ({ isMenuOpen: !isMenuOpen, activeTab: CourseProgressPageMenuTab.Progress }));
    }

    private createQuizFormGroup(): Observable<QuizFormGroup | undefined> {
        return this.viewData$.pipe(
            map((viewData) => {
                if (viewData?.currentUnit.type !== UnitType.Quiz && viewData?.currentUnit.type !== UnitType.QuizResults) {
                    return undefined;
                }

                return new FormGroup(
                    {
                        ...Object.fromEntries(
                            viewData.currentUnit.quizData.map((question) => [
                                question.id,
                                new FormGroup({
                                    ...Object.fromEntries(
                                        question.answers.map((answer) => [
                                            answer.id,
                                            new FormControl(answer.wasSelected ?? false, { nonNullable: true }),
                                        ])
                                    ),
                                }),
                            ])
                        ),
                    },
                    { validators: [CourseProgressPageComponent.answersGroupValidator] }
                );
            })
        );
    }
}
