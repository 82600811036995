import { Location } from "@angular/common";
import { HttpStatusCode } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, ResolveFn, Router } from "@angular/router";
import { DtmAuthGuard } from "@dtm-frontend/shared/auth";
import { catchError, from, lastValueFrom, map, of } from "rxjs";
import { ActivatedAppRouteSnapshot, RouteType } from "./elearning-routing.module";
import { CourseProgressPageViewData, EnrollmentViewData, ExamPageViewData, ViewData } from "./models/elearning.model";
import { CourseDescriptionProvider } from "./pages/course-progress-page/course-progress-page.component";
import { ElearningApiService } from "./services/elearning-api.service";

function clearNavigationExtrasState(location: Location, route: ActivatedRouteSnapshot) {
    setTimeout(() => location.replaceState([...route.url.map((segment) => segment.path)].join("/"), undefined, null));
}

@Injectable()
export class ElearningHateoasViewDataResolver {
    constructor(
        private readonly eLearningApi: ElearningApiService,
        private readonly authGuard: DtmAuthGuard,
        private readonly router: Router
    ) {}

    public resolve(route: ActivatedAppRouteSnapshot): Promise<ViewData | undefined> {
        return lastValueFrom(
            this.eLearningApi.getViewData(route).pipe(
                catchError((error) => {
                    if (route.data.routeType === "course-enrollment" && error.status === HttpStatusCode.Forbidden) {
                        this.router.navigate(["catalog/available-courses/not-allowed"], {
                            queryParams: {
                                name: route.queryParams.name,
                            },
                        });

                        return of(undefined);
                    }
                    if (route.data.routeType === "course-enrollment" && error.status === HttpStatusCode.TooManyRequests) {
                        const retryAfterDate = new Date(error.headers.get("retry-after"));
                        const isRetryAfterValidDate = retryAfterDate instanceof Date && !isNaN(retryAfterDate.getTime());

                        this.router.navigate(["catalog/available-courses/not-allowed"], {
                            queryParams: {
                                name: route.queryParams.name,
                                notAllowedUntil: isRetryAfterValidDate ? retryAfterDate : undefined,
                            },
                        });

                        return of(undefined);
                    }
                    if (
                        error.status === HttpStatusCode.Unauthorized ||
                        (route.data.routeType !== "course-enrollment" && error.status === HttpStatusCode.Forbidden)
                    ) {
                        return from(this.authGuard.isAccessAllowed(route, this.router.routerState.snapshot)).pipe(map(() => undefined));
                    }

                    console.error(error);

                    return of(undefined);
                })
            )
        );
    }
}

export const examViewDataResolver: ResolveFn<ExamPageViewData | void> = async (route: ActivatedRouteSnapshot) => {
    const viewDataResolver = inject(ElearningHateoasViewDataResolver);
    const router = inject(Router);
    const location = inject(Location);
    const navigationState = router.getCurrentNavigation()?.extras?.state;

    if (route.data.routeType === RouteType.ExamAnswer && !navigationState) {
        router.navigate([...route.url.slice(0, -1).map((segment) => segment.path), "exam"]);

        return;
    }

    const viewData = (await viewDataResolver.resolve(route as ActivatedAppRouteSnapshot)) as ExamPageViewData;
    clearNavigationExtrasState(location, route);

    if (route.data.routeType !== RouteType.ExamResult && !viewData.question) {
        router.navigate([viewData.nextLink ?? "/"]);
    }

    return viewData;
};

export const quizAnswersViewDataResolver: ResolveFn<CourseProgressPageViewData | void> = async (route: ActivatedRouteSnapshot) => {
    const viewDataResolver = inject(ElearningHateoasViewDataResolver);
    const router = inject(Router);
    const location = inject(Location);

    if (!router.getCurrentNavigation()?.extras?.state) {
        router.navigate([...route.url.slice(0, -1).map((segment) => segment.path)]);

        return;
    }

    const viewData = (await viewDataResolver.resolve(route as ActivatedAppRouteSnapshot)) as CourseProgressPageViewData;
    clearNavigationExtrasState(location, route);

    return viewData;
};

export const courseDescriptionProviderResolver: ResolveFn<CourseDescriptionProvider> = () => {
    const apiService = inject(ElearningApiService);

    return (link: string | undefined) => {
        if (!link) {
            return of(undefined);
        }

        return apiService.getCourseDescription(link);
    };
};

export const courseEnrollmentRedirectResolver: ResolveFn<void> = async (route: ActivatedRouteSnapshot) => {
    const viewDataResolver = inject(ElearningHateoasViewDataResolver);
    const router = inject(Router);

    const viewData = (await viewDataResolver.resolve(route as ActivatedAppRouteSnapshot)) as EnrollmentViewData;
    router.navigate([viewData.nextLink ?? "/"]);

    return;
};
