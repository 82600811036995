import { InjectionToken } from "@angular/core";

export interface ELearningEndpoints {
    rootUri: string;
    termsOfUse: string;
    accessibilityStatement: string;
}

export const ELEARNING_ENDPOINTS = new InjectionToken<ELearningEndpoints>("eLearning endpoints");
export const UAV_APP_URL = new InjectionToken<string>("UAV app URL");
export const MAIN_PAGE_APP_URL = new InjectionToken<string>("Main page app URL");
