import { DOCUMENT } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, map } from "rxjs";
import { ActivatedAppRouteSnapshot, RouteType } from "../elearning-routing.module";
import { ELEARNING_ENDPOINTS, ELearningEndpoints } from "../elearning.tokens";
import {
    CourseDetailsPageViewData,
    CourseProgressPageViewData,
    CourseSummaryPageViewData,
    ExamPageViewData,
    QuizAnswersFormGroupValue,
    UnitType,
    ViewData,
} from "../models/elearning.model";
import { courseIdQueryParam } from "../pages/course-details-page/course-details-page.component";
import { startExamNavigationStateParam } from "../pages/course-progress-page/course-progress-page.component";
import { ExamAnswersFormGroupValue, isFinishedByTimeoutNavigationStateParam } from "../pages/exam-page/exam-page.component";
import { CatalogPageViewData } from "./../models/elearning.model";
import {
    CatalogPageViewDataResponseBody,
    CourseDetailsViewDataResponseBody,
    CourseEnrollmentViewDataResponseBody,
    CourseSummaryViewDataResponseBody,
    ElearningApiServiceConverters,
    ExamDescriptionViewDataResponseBody,
    ExamQuestionViewDataResponseBody,
    ExamResultViewDataResponseBody,
    LessonViewDataResponseBody,
    QuizResultsResponseBody,
    QuizViewDataResponseBody,
} from "./elearning-api.service.converters";

@Injectable()
export class ElearningApiService {
    private readonly converters = new ElearningApiServiceConverters(this.endpoints);

    constructor(
        private readonly http: HttpClient,
        @Inject(ELEARNING_ENDPOINTS) private readonly endpoints: ELearningEndpoints,
        private readonly router: Router,
        @Inject(DOCUMENT) private readonly document: Document
    ) {}

    public getViewData(route: ActivatedAppRouteSnapshot): Observable<ViewData> {
        const dataRequestUrl = new URL(`${this.endpoints.rootUri}/${route.url.join("/")}`, this.document.location.origin).toString();

        switch (route.data.routeType) {
            case RouteType.Catalog:
                return this.getCatalogViewData(dataRequestUrl);
            case RouteType.CourseDetails:
                return this.getCourseDetailsViewData(dataRequestUrl);
            case RouteType.CourseEnrollment:
                return this.enrollCourse(route, dataRequestUrl);
            case RouteType.CourseSummary:
                return this.getCourseSummaryViewData(route, dataRequestUrl);
            case RouteType.CourseProgress:
                return this.getCourseProgressViewData(route, dataRequestUrl);
            case RouteType.CourseQuizAnswers:
                return this.sendCourseQuizAnswers(dataRequestUrl);
            case RouteType.ExamResult:
            case RouteType.ExamQuestion:
                return this.getExamViewData(route, dataRequestUrl);
            case RouteType.ExamAnswer:
                return this.sendExamAnswers(dataRequestUrl);
        }
    }

    private enrollCourse(route: ActivatedAppRouteSnapshot, dataRequestUrl: string): Observable<ViewData> {
        return this.http
            .post<CourseEnrollmentViewDataResponseBody>(dataRequestUrl, { courseId: route.queryParams[courseIdQueryParam] })
            .pipe(map((response) => this.converters.convertCourseEnrollmentViewDataResponseBodyToViewData(response)));
    }

    private sendCourseQuizAnswers(dataRequestUrl: string): Observable<CourseProgressPageViewData> {
        const answersPayload = this.converters.convertQuizAnswersFromGroupValueToQuizAnswersRequestPayload(
            this.router.getCurrentNavigation()?.extras.state as QuizAnswersFormGroupValue
        );

        return this.http
            .post<QuizResultsResponseBody>(dataRequestUrl, answersPayload)
            .pipe(map((response) => this.converters.convertQuizResultsResponseBodyIntoViewData(response)));
    }

    private getCatalogViewData(dataRequestUrl: string): Observable<CatalogPageViewData> {
        return this.http
            .get<CatalogPageViewDataResponseBody>(dataRequestUrl)
            .pipe(map((response) => this.converters.convertCatalogPageViewDataResponseBodyToViewData(response)));
    }

    private getCourseDetailsViewData(dataRequestUrl: string): Observable<CourseDetailsPageViewData> {
        return this.http
            .get<CourseDetailsViewDataResponseBody>(dataRequestUrl)
            .pipe(map((response) => this.converters.convertCourseDetailsViewDataResponseBodyToViewData(response)));
    }

    public getCourseDescription(dataRequestUrl: string): Observable<string> {
        return this.http.get<{ description: string }>(dataRequestUrl).pipe(map((response) => response.description));
    }

    private getCourseSummaryViewData(route: ActivatedAppRouteSnapshot, dataRequestUrl: string): Observable<CourseSummaryPageViewData> {
        return this.http
            .get<CourseSummaryViewDataResponseBody>(dataRequestUrl)
            .pipe(map((response) => this.converters.convertCourseSummaryViewDataResponseBodyToViewData(response)));
    }

    private getCourseProgressViewData(route: ActivatedAppRouteSnapshot, dataRequestUrl: string): Observable<CourseProgressPageViewData> {
        switch (route.params.unitType.toUpperCase().replaceAll("-", "_")) {
            case UnitType.Lesson:
                return this.http
                    .get<LessonViewDataResponseBody>(dataRequestUrl)
                    .pipe(map((response) => this.converters.convertLessonViewDataResponseBodyToViewData(response)));
            case UnitType.Quiz:
                return this.http
                    .get<QuizViewDataResponseBody>(dataRequestUrl)
                    .pipe(map((response) => this.converters.convertQuizViewDataResponseBodyToViewData(response)));
            case UnitType.ExamDescription:
                return this.http
                    .get<ExamDescriptionViewDataResponseBody>(dataRequestUrl)
                    .pipe(map((response) => this.converters.convertExamDescriptionViewDataResponseBodyToViewData(response)));
            default:
                throw new Error(`Invalid HATEOAS UnitType: ${route.params.unitType}`);
        }
    }

    private getExamViewData(route: ActivatedAppRouteSnapshot, dataRequestUrl: string): Observable<ExamPageViewData> {
        switch (route.data.routeType) {
            case RouteType.ExamResult:
                return this.http
                    .get<ExamResultViewDataResponseBody>(dataRequestUrl)
                    .pipe(map((response) => this.converters.convertExamResultViewDataResponseBodyToViewData(response)));
            case RouteType.ExamQuestion: {
                const navigationState = this.router.getCurrentNavigation()?.extras.state;

                if (navigationState?.[startExamNavigationStateParam]) {
                    return this.http
                        .post<ExamQuestionViewDataResponseBody>(dataRequestUrl, {})
                        .pipe(map((response) => this.converters.convertExamQuestionViewDataResponseBodyToViewData(response)));
                } else if (navigationState?.[isFinishedByTimeoutNavigationStateParam]) {
                    return this.http
                        .delete<ExamQuestionViewDataResponseBody>(dataRequestUrl, {})
                        .pipe(map((response) => this.converters.convertExamQuestionViewDataResponseBodyToViewData(response)));
                }
                break;
            }
            default:
                break;
        }

        return this.http
            .get<ExamQuestionViewDataResponseBody>(dataRequestUrl)
            .pipe(map((response) => this.converters.convertExamQuestionViewDataResponseBodyToViewData(response)));
    }

    private sendExamAnswers(dataRequestUrl: string): Observable<ExamPageViewData> {
        const answersPayload = this.converters.convertExamAnswersFromGroupValueToExamAnswersRequestPayload(
            this.router.getCurrentNavigation()?.extras.state as ExamAnswersFormGroupValue
        );

        return this.http
            .post<ExamQuestionViewDataResponseBody>(dataRequestUrl, answersPayload)
            .pipe(map((response) => this.converters.convertExamQuestionViewDataResponseBodyToViewData(response)));
    }
}
