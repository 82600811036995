import { NgModule } from "@angular/core";
import { ActivatedRouteSnapshot, Data, ResolveFn, Route, RouterModule, TitleStrategy } from "@angular/router";
import { ElearningPageTitleStrategy } from "./elearning-page.title-strategy";
import {
    ElearningHateoasViewDataResolver,
    courseDescriptionProviderResolver,
    courseEnrollmentRedirectResolver,
    examViewDataResolver,
    quizAnswersViewDataResolver,
} from "./hateoas-view-data.resolvers";
import { ViewData } from "./models/elearning.model";
import { CatalogPageComponent } from "./pages/catalog-page/catalog-page.component";
import { CourseDetailsPageComponent } from "./pages/course-details-page/course-details-page.component";
import { CourseNotAllowedComponent } from "./pages/course-not-allowed/course-not-allowed.component";
import {
    CourseDescriptionProvider,
    CourseProgressPageComponent,
    courseDescriptionProviderResolveKey,
} from "./pages/course-progress-page/course-progress-page.component";
import { CourseSummaryPageComponent } from "./pages/course-summary-page/course-summary-page.component";
import { ExamPageComponent } from "./pages/exam-page/exam-page.component";

export enum RouteType {
    Catalog = "catalog",
    CourseDetails = "course-details",
    CourseEnrollment = "course-enrollment",
    CourseProgress = "course-progress",
    CourseQuizAnswers = "course-quiz-answers",
    CourseSummary = "course-summary",
    ExamQuestion = "exam-question",
    ExamAnswer = "exam-answer",
    ExamResult = "exam-result",
}

export type RouteData = Data & {
    routeType: RouteType;
};

type AppRoute = Route & {
    data: RouteData;
    resolve: Partial<{
        viewData: typeof ElearningHateoasViewDataResolver | ResolveFn<ViewData | CourseDescriptionProvider | void>;
    }>;
};

export interface ActivatedAppRouteSnapshot extends ActivatedRouteSnapshot {
    data: RouteData;
}

function createAppRoute(
    path: string,
    component: Route["component"],
    routeType: RouteType,
    viewDataResolver: typeof ElearningHateoasViewDataResolver | ResolveFn<ViewData | CourseDescriptionProvider | void>,
    options?: Partial<{
        resolvers: Route["resolve"];
        routeProperties: Omit<Partial<Route>, "resolve">;
    }>
): AppRoute {
    return {
        path,
        component,
        ...options?.routeProperties,
        data: {
            ...options?.routeProperties?.data,
            routeType,
        },
        resolve: {
            viewData: viewDataResolver,
            ...options?.resolvers,
        },
    };
}

const routes: AppRoute[] = [
    createAppRoute("enrollments", CourseProgressPageComponent, RouteType.CourseEnrollment, courseEnrollmentRedirectResolver),
    createAppRoute(
        "catalog/available-courses/:id/details",
        CourseDetailsPageComponent,
        RouteType.CourseDetails,
        ElearningHateoasViewDataResolver
    ),
    createAppRoute(
        "course-progresses/:progressId/quiz/:unitNumber/quiz-answers",
        CourseProgressPageComponent,
        RouteType.CourseQuizAnswers,
        quizAnswersViewDataResolver,
        {
            resolvers: {
                [courseDescriptionProviderResolveKey]: courseDescriptionProviderResolver,
            },
        }
    ),
    createAppRoute("course-progresses/:progressId/exam", ExamPageComponent, RouteType.ExamQuestion, examViewDataResolver, {
        routeProperties: {
            runGuardsAndResolvers: "always",
        },
    }),
    createAppRoute("course-progresses/:progressId/exam-question-answers", ExamPageComponent, RouteType.ExamAnswer, examViewDataResolver, {
        routeProperties: {
            runGuardsAndResolvers: "always",
        },
    }),
    createAppRoute("course-progresses/:progressId/exam-result", ExamPageComponent, RouteType.ExamResult, examViewDataResolver),
    createAppRoute(
        "course-progresses/:progressId/summary",
        CourseSummaryPageComponent,
        RouteType.CourseSummary,
        ElearningHateoasViewDataResolver
    ),
    createAppRoute(
        "course-progresses/:progressId/:unitType/:unitNumber",
        CourseProgressPageComponent,
        RouteType.CourseProgress,
        ElearningHateoasViewDataResolver,
        {
            resolvers: {
                [courseDescriptionProviderResolveKey]: courseDescriptionProviderResolver,
            },
        }
    ),
    createAppRoute("catalog", CatalogPageComponent, RouteType.Catalog, ElearningHateoasViewDataResolver),
];

@NgModule({
    imports: [
        RouterModule.forChild([
            ...routes,
            {
                path: "",
                pathMatch: "full",
                redirectTo: "catalog",
            },
            { path: "catalog/available-courses/not-allowed", component: CourseNotAllowedComponent },
        ]),
    ],
    providers: [ElearningHateoasViewDataResolver, { provide: TitleStrategy, useClass: ElearningPageTitleStrategy }],
    exports: [RouterModule],
})
export class ELearningRoutingModule {}
