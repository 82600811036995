<div *ngrxLet="{ queryParams: route.queryParams } as vm">
    <article class="course-introduction">
        <header class="header">
            <div class="header-text">
                <h2>{{ vm.queryParams.name }}</h2>
            </div>
        </header>
        <section class="content">
            <h3>
                {{
                    (vm.queryParams.notAllowedUntil ? "eLearningLib.notAllowed.notAllowedUntil" : "eLearningLib.notAllowed.forbiddenInfo")
                        | transloco : { notAllowedUntilValue: vm.queryParams.notAllowedUntil | localizeDate }
                }}
            </h3>
            <button type="button" class="button-primary" routerLink="/">
                {{ "eLearningLib.notAllowed.returnToMainPage" | transloco }}
            </button>
        </section>
    </article>
</div>
