import { CourseTag } from "../services/elearning-api.service.converters";

export enum CourseStatus {
    DuringTheory = "DURING_THEORY",
    DuringExam = "DURING_EXAM",
    Passed = "PASSED",
    Failed = "FAILED",
}

export enum UnitType {
    Lesson = "LESSON",
    Quiz = "QUIZ",
    ExamDescription = "EXAM_DESCRIPTION",
    ExamQuestion = "EXAM_QUESTION",
    Summary = "SUMMARY",
    QuizResults = "QUIZ_RESULTS",
}

export enum CourseDataType {
    CourseDetails = "course-details",
    UserCourseDetails = "user-course-details",
}

export enum CourseCategoryType {
    First = "first",
    Second = "second",
    Other = "other",
}

interface CourseDataBase {
    id: string;
    type: CourseDataType;
    category: {
        name: string;
        type: CourseCategoryType;
        tags: CourseTag[];
    };
    header: string;
    link: string | undefined;
}

export interface CourseProgress {
    completionPercentage: number;
}

export interface CourseDetailsData extends CourseDataBase {
    type: CourseDataType.CourseDetails;
    description: string;
}

export interface UserCourseDetailsData extends CourseDataBase {
    type: CourseDataType.UserCourseDetails;
    progress: CourseProgress;
    status: CourseStatus;
    finishedAt?: Date;
}

export type CourseData = CourseDetailsData | UserCourseDetailsData;

export interface CatalogCoursesCategory {
    name: string;
    type: CourseCategoryType;
    courses: CourseDetailsData[];
}

export interface ViewDataBase {
    titleData: { type: string } & Record<string, string>;
}

export interface CatalogPageViewData extends ViewDataBase {
    userCourses: UserCourseDetailsData[];
    categories: CatalogCoursesCategory[];
}

interface ExamPageViewDataBase extends ViewDataBase {
    categoryType: CourseCategoryType;
    tags: CourseTag[];
    courseName: string;
    question?: {
        id: number;
        isMultipleChoice: boolean;
        textHtml: string;
        answers: {
            id: number;
            textHtml: string;
        }[];
    };
    nextLink: string | undefined;
}

export type ExamPageViewData =
    | (
          | {
                type: UnitType.ExamQuestion;
                timeLeftTimestamp: number;
                timeLeftSeconds: number;
                initialTimeSeconds: number;
                currentQuestionIndex: number;
                totalQuestionsCount: number;
                finishLink: string | undefined;
            }
          | {
                type: UnitType.Summary;
                textHtml: string;
                hasPassed: boolean;
                percentage: number;
            }
      ) &
          ExamPageViewDataBase;

export interface CourseSummaryPageViewData extends ViewDataBase {
    unitType: UnitType.Summary;
    textHtml: string;
    tags: CourseTag[];
    courseName?: string;
    categoryType: CourseCategoryType;
    nextLink: string | undefined;
}

export enum CourseUnitStatus {
    Visited = "visited",
    Active = "active",
    Unvisited = "unvisited",
}

type QuizData = {
    id: number;
    questionTextHtml: string;
    isMultipleChoice: boolean;
    answers: {
        id: number;
        textHtml: string;
        isCorrect?: boolean;
        wasSelected?: boolean;
    }[];
}[];

export interface QuizAnswersFormGroupValue {
    [questionId: number]:
        | Partial<{
              [answerId: number]: boolean;
          }>
        | undefined;
}

type CurrentUnitData =
    | { type: UnitType.Lesson; textHtml: string }
    | { type: UnitType.ExamDescription; textHtml: string }
    | { type: UnitType.Quiz; quizData: QuizData }
    | { type: UnitType.QuizResults; quizData: QuizData; validityPercentage: number; hasPassed: boolean };

export type CurrentCourseUnit = { type: UnitType; title: string; chapterTitle: string } & CurrentUnitData;

export interface CourseUnit {
    status: CourseUnitStatus;
    type: UnitType;
    title: string;
    link: string | undefined;
}

export interface CourseProgressPageViewData extends ViewDataBase {
    name: string;
    categoryType: CourseCategoryType;
    tags: CourseTag[];
    description: {
        header: string;
        descriptionLink: string | undefined;
    };
    progress: CourseProgress;
    currentUnit: CurrentCourseUnit;
    chapters: {
        title: string;
        units: CourseUnit[];
    }[];
    isNextLinkSummary: boolean;
    previousLink: string | undefined;
    nextLink: string | undefined;
}

export interface CourseDetailsPageViewData extends ViewDataBase {
    courseId: string;
    name: string;
    categoryType: CourseCategoryType;
    description: {
        header: string;
        textHtml: string;
    };
    tags: CourseTag[];
    progress?: CourseProgress;
    requirements: CourseDetailsData[];
    previousLink: string | undefined;
    nextLink: string | undefined;
}

export interface EnrollmentViewData extends ViewDataBase {
    nextLink: string | undefined;
}

export type ViewData =
    | CatalogPageViewData
    | CourseProgressPageViewData
    | EnrollmentViewData
    | CourseDetailsPageViewData
    | CourseSummaryPageViewData;
