// NOTE: This is a copy of https://github.com/marcreichel/angular-carousel/ to avoid peer dependencies issues

import { CommonModule } from "@angular/common";
import {
    AfterContentChecked,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChildren,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    QueryList,
    SimpleChanges,
    ViewEncapsulation,
} from "@angular/core";
import { AngularCarouselSlideDirective } from "./slide.directive";

const MAX_CAROUSEL_SLIDE_DURATION = 500;

// TODO: DTM-5232 - Merge with or replace shared-ui carousel?

@Component({
    selector: "elearning-lib-angular-carousel",
    templateUrl: "./carousel.component.html",
    styleUrls: ["./carousel.component.scss"],
    encapsulation: ViewEncapsulation.None, // NOTE: this is because the styles are global
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [AngularCarouselSlideDirective, CommonModule],
    standalone: true,
})
export class AngularCarouselComponent implements OnChanges, AfterContentChecked, OnDestroy {
    @Input() public bullets = true;
    @Input() public infinite = false;
    @Input() public auto = false;
    @Input() public duration = MAX_CAROUSEL_SLIDE_DURATION;
    @Input() public nextButtonAriaLabel = "";
    @Input() public previousButtonAriaLabel = "";

    @Output() public page: EventEmitter<number> = new EventEmitter<number>();
    @Output() public previous: EventEmitter<number> = new EventEmitter<number>();
    @Output() public next: EventEmitter<number> = new EventEmitter<number>();

    @ContentChildren(AngularCarouselSlideDirective) public slides: QueryList<AngularCarouselSlideDirective> =
        new QueryList<AngularCarouselSlideDirective>();

    public currentSlide = 0;

    private interval!: ReturnType<typeof setInterval>;

    constructor(private cdr: ChangeDetectorRef) {}

    public ngAfterContentChecked(): void {
        this.cdr.detectChanges();
        this.currentSlide = Math.max(Math.min(this.currentSlide, this.slides.length - 1), 0);
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.auto) {
            if (changes.auto.currentValue) {
                this.startInterval();
            } else {
                this.stopInterval();
            }
        }
    }

    public ngOnDestroy(): void {
        this.stopInterval();
    }

    public previousSlide($event?: MouseEvent): void {
        if ($event) {
            $event.preventDefault();
            $event.stopPropagation();
        }
        if (this.currentSlide <= 0) {
            this.currentSlide = this.slides.length - 1;
        } else {
            this.currentSlide--;
        }

        this.emitPrevious();
        this.restartInterval();
    }

    public nextSlide($event?: MouseEvent): void {
        if ($event) {
            $event.preventDefault();
            $event.stopPropagation();
        }
        if (this.currentSlide >= this.slides.length - 1) {
            this.currentSlide = 0;
        } else {
            this.currentSlide++;
        }

        this.emitNext();
        this.restartInterval();
    }

    public goToSlide(slide: number, $event?: MouseEvent): void {
        if ($event) {
            $event.preventDefault();
            $event.stopPropagation();
        }

        const index = slide - 1;

        if (index < 0 || index >= this.slides.length) {
            return;
        }

        if (index === this.currentSlide) {
            return;
        }

        this.currentSlide = index;
        this.emitPage();
        this.restartInterval();
    }

    public startInterval(): void {
        if (!this.auto) {
            return;
        }
        this.interval = setInterval(() => {
            this.nextSlide();
        }, Math.max(this.duration, MAX_CAROUSEL_SLIDE_DURATION));
    }

    public stopInterval(): void {
        if (!this.auto) {
            return;
        }
        clearInterval(this.interval);
    }

    private emitPrevious(): void {
        this.previous.emit(this.currentSlide + 1);
        this.emitPage();
    }

    private emitNext(): void {
        this.next.emit(this.currentSlide + 1);
        this.emitPage();
    }

    private emitPage(): void {
        this.page.emit(this.currentSlide + 1);
    }

    private restartInterval(): void {
        this.stopInterval();
        this.startInterval();
    }
}
