import { createDynamicEnvironment } from "@dtm-frontend/shared/utils";
import type DynamicConfiguation from "../assets/dynamic.config.json";
import { ELearningClientEnvironment } from "./environment.model";

export const dynamicEnvironment = createDynamicEnvironment<ELearningClientEnvironment, typeof DynamicConfiguation>(
    fetch("../assets/dynamic.config.json").then((config) => config.json()),
    ({ apiDomain, keycloakConfig, elearningApiEndpoint }) => {
        const apiUrl = `https://${apiDomain}/api`;

        return {
            production: false,
            name: "dev",
            bearerExcludedUrls: [],
            uavAppUrl: "https://uav.pansa.pl",
            mainPageAppUrl: "https://drony.gov.pl",
            keycloakConfig,
            elearningEndpoints: {
                rootUri: elearningApiEndpoint,
                termsOfUse: `${apiUrl}/terms-of-service/uav-app/current`,
                accessibilityStatement: `${apiUrl}/accessibility-statement`,
            },
        };
    }
);
