import { UploadedFile } from "@dtm-frontend/shared/utils";
import { Address } from "./address.models";
import { PhoneNumber } from "./phone-number.models";
import { MissionType } from "./tactical.models";
import { UavType } from "./uav.models";

export interface DtmLocations {
    id: string;
    name: string;
}

export interface OperationRestrictionsModel {
    permitNumber: string;
    permitConfirmationNumber?: string;
    expirationDate: {
        dateFrom: Date;
        dateTo: Date;
    };
    operationTarget: string;
    localization?: LocalizationType;
    dtm: DtmLocations;
    locationDescription: string;
    riskMethodology: RiskAssessmentReference;
    sail: Sail;
    operationType: MissionType;
    isDangerousGoodsTransport: boolean;
    kmlFile?: UploadedFile[];
    pdraNumber: string | null;
}

export interface BasicDataModel {
    caaContactUser: CaaContactPerson;
    operator: Operator;
    operatorContact: ContactPerson;
    operationRestrictions: OperationRestrictionsModel;
    groundRiskCharacteristic: GroundRiskCharacteristicModel;
    operationalVolumeHeight: OperationalVolumeHeightModel;
    finalRiskArea: FinalRiskAreaModel;
    groundRiskMitigation: GroundRiskMitigationModel;
    airRiskMitigation: AirRiskMitigationModel;
    additionalAccidentType: AdditionalAccidentModel;
}

export interface UavDetailsModel {
    uavModel: PermitUavModel;
    operatedNumber: number;
    isDroneSwarm: boolean;
    serialNumbers: string;
    maxUavWidth: number;
    maxFlightSpeed: number;
    takeOffWeight: number;
    additionalTechRequirements: string;
    designVerificationReport: string;
    certificateOfAirworthiness: string;
    noiseCertificate: string;
    riskMitigation: GroundRiskMitigationType;
    comments?: string;
}

export interface Permit {
    id?: string;
    basicData: BasicDataModel;
    uavDetails: UavDetailsModel;
}

export interface AdditionalAccidentModel {
    hasInsurance: boolean;
    operationsManualReference: string;
    additionalLimitations: string;
}

export interface FinalRiskAreaModel {
    operationArea: OperationalArc;
    adjacentArea: OperationalArc;
    operationalAirSpaces: OperationalAirspaceClassType[];
    otherAirspace?: string;
}

export interface OperationalVolumeHeightModel {
    operationalVolumeHeightLimit: number;
}

export interface GroundRiskMitigationModel {
    strategicMitigationMeasure: GroundRiskMitigationType;
    emergencyResponsePlan?: GroundRiskMitigationType;
    avoidingFlightsOverPeople?: AvoidingFlightsOverPeopleType;
}

export interface AirRiskMitigationModel {
    strategicMitigationMeasure: AirRiskMitigationType;
    tacticalAirRiskMitigationMeasure: TacticalAirRiskMitigationType;
    tacticalAirRiskMitigationRequirements?: string;
    containmentReachedLevel: ContainmentLevelReachedType;
    competencies: Competency[];
    additionalCompetencies: string[];
    competenciesDescription: string;
    staffCompetencies: string[];
    staffCompetenciesDescription: string;
}

export interface GroundRiskCharacteristicModel {
    groundAreaCharacteristic: GroundAreaCharacteristicType;
    controlledGroundAreaCharacteristic?: ControlledGroundAreaCharacteristicType;
    finalGroundRiskClass?: number;
    adjacentGroundAreaCharacteristic: AdjacentGroundAreaCharacteristicType;
    adjacentGroundAreaExtent?: number;
}

export interface Competency {
    id: string;
    name: string;
}

export enum GroundRiskMitigationType {
    Absence = "ABSENCE",
    LowRobustness = "LOW_ROBUSTNESS",
    AverageRobustness = "AVERAGE_ROBUSTNESS",
    HighRobustness = "HIGH_ROBUSTNESS",
}

export enum AvoidingFlightsOverPeopleType {
    Absence = "ABSENCE",
    LowRobustness = "LOW_ROBUSTNESS",
}

export enum AirRiskMitigationType {
    Absence = "ABSENCE",
    OperationalMitigation = "OPERATIONAL_MITIGATION",
    CommonRulesAndAirspace = "COMMON_RULES_AND_AIRSPACE",
    OperationalMitigationAndCommonRulesAndAirspace = "OPERATIONAL_MITIGATION_COMMON_RULES_AND_AIRSPACE",
}

export enum GroundAreaCharacteristicType {
    Controlled = "CONTROLLED",
    PeopleAssembly = "PEOPLE_ASSEMBLY",
    DenseUrban = "DENSE_URBAN",
    Urban = "URBAN",
    Suburban = "SUBURBAN",
    Populated = "POPULATED",
    SparselyPopulated = "SPARSELY_POPULATED",
    Rural = "RURAL",
}

export enum ControlledGroundAreaCharacteristicType {
    Rural = "RURAL",
    SparselyPopulated = "SPARSELY_POPULATED",
    Populated = "POPULATED",
    Suburban = "SUBURBAN",
    Urban = "URBAN",
    DenseUrban = "DENSE_URBAN",
}

export enum AdjacentGroundAreaCharacteristicType {
    PeopleAssembly = "PEOPLE_ASSEMBLY",
    DenseUrban = "DENSE_URBAN",
    Urban = "URBAN",
    Suburban = "SUBURBAN",
    Populated = "POPULATED",
    SparselyPopulated = "SPARSELY_POPULATED",
    Rural = "RURAL",
    Controlled = "CONTROLLED",
}

export enum ContainmentLevelReachedType {
    BASIC = "BASIC",
    HIGHER = "HIGHER",
}

export enum TacticalAirRiskMitigationType {
    VlosSeeAndAvoid = "VLOS_SEE_AND_AVOID",
    BVlosArcA = "BVLOS_ARC_A",
    BVlosArcB = "BVLOS_ARC_B",
    BVlosArcC = "BVLOS_ARC_C",
    BVlosArcD = "BVLOS_ARC_D",
}

export enum OperationalAirspaceClassType {
    C = "C",
    D = "D",
    G = "G",
    USpace = "U_SPACE",
    Other = "OTHER",
}

export enum Sail {
    Sail1 = 1,
    Sail2 = 2,
    // eslint-disable-next-line no-magic-numbers
    Sail3 = 3,
    // eslint-disable-next-line no-magic-numbers
    Sail4 = 4,
    // eslint-disable-next-line no-magic-numbers
    Sail5 = 5,
    // eslint-disable-next-line no-magic-numbers
    Sail6 = 6,
}

export enum OperationalArc {
    ARC_A = 1,
    ARC_B = 2,
    // eslint-disable-next-line no-magic-numbers
    ARC_C = 3,
    // eslint-disable-next-line no-magic-numbers
    ARC_D = 4,
}

export enum LocalizationType {
    Kml = "KML",
    Dtm = "DTM",
}

export enum RiskAssessmentReference {
    SORA2_0 = "SORA2_0",
    SORA2_0_ALTMOC = "SORA2_0_ALTMOC",
    PDRA = "PDRA",
}

export enum SpecificPermitFlightWizardSteps {
    BasicInfo = "BasicInfo",
    UavDetails = "UavDetails",
    Summary = "Summary",
}

export interface Operator {
    id: string;
    name: string;
    number: string;
    models?: PermitUavModel[];
}

export interface ContactPerson {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber?: PhoneNumber;
    position?: string;
}

export interface AssociationOperator extends Operator {
    associationNationalCourtRegister?: string;
    associationRegistrationNumber?: string;
    address: Address;
}

export interface PermitUavModel {
    id: string;
    name: string;
    manufacturer: string;
    type: UavType;
    technicalSpecification: {
        maxDroneWidth: number;
        takeOffMass: number;
        maxFlightSpeed: number;
    };
}

export interface CaaContactPerson {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber?: PhoneNumber;
}

export enum AssociationOperationType {
    VLOS = "VLOS",
    FPV = "FPV",
}

export interface AssociationPermit {
    caaContact: CaaContactPerson;
    flightPurpose: string;
    id?: string;
    isInsurance: boolean;
    locations: string;
    operationType: AssociationOperationType;
    operatorContact: ContactPerson;
    permitNumber: string;
    type?: PermitType;
    expirationDate: {
        dateFrom: Date;
        dateTo: Date;
    };
    associationInfo: AssociationOperator;
}

export enum PermitType {
    Association = "ASSOCIATION",
    OperationPermission = "OPERATION_PERMISSION",
    CrossBorder = "CROSS_BORDER",
}
