import { ChangeDetectionStrategy, Component } from "@angular/core";
import { MatLegacyTabChangeEvent as MatTabChangeEvent } from "@angular/material/legacy-tabs";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";

interface AdminPanelComponentState {
    selectedTab: AdminTabType;
}

enum AdminTabType {
    VIEW = 0,
    HTML = 1,
}

@Component({
    selector: "elearning-admin-panel",
    templateUrl: "./admin-panel.component.html",
    styleUrls: ["./admin-panel.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class AdminPanelComponent {
    protected readonly selectedTab$ = this.localStore.selectByKey("selectedTab");

    constructor(private readonly localStore: LocalComponentStore<AdminPanelComponentState>) {
        this.localStore.setState({ selectedTab: AdminTabType.VIEW });
    }

    protected changeTab(tab: MatTabChangeEvent): void {
        this.localStore.patchState({
            selectedTab: tab.index === AdminTabType.VIEW ? AdminTabType.VIEW : AdminTabType.HTML,
        });
    }
}
