import { ChangeDetectionStrategy, Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { UntilDestroy } from "@ngneat/until-destroy";
import { map } from "rxjs";
import { CourseSummaryPageViewData } from "../../models/elearning.model";
import { getTagIcon } from "../../utils/get-tag-icon";

@UntilDestroy()
@Component({
    templateUrl: "./course-summary-page.component.html",
    styleUrls: ["../common-page.styles.scss", "./course-summary-page.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CourseSummaryPageComponent {
    protected readonly getTagIcon = getTagIcon;

    protected readonly viewData$ = this.route.data.pipe(map(({ viewData }) => viewData as CourseSummaryPageViewData));

    constructor(private readonly route: ActivatedRoute) {}
}
