import { UIActions } from "@dtm-frontend/shared/ui";
import { getDefaultLang, LANGUAGE_CONFIGURATION } from "@dtm-frontend/shared/ui/i18n";
import { TranslocoService } from "@jsverse/transloco";
import { Store } from "@ngxs/store";
import { lastValueFrom } from "rxjs";
import { ActionsHandler } from "../services/actions.handler";

export function initialAppSetup(actionHandler: ActionsHandler, store: Store, translocoService: TranslocoService) {
    return () => {
        const browserLanguage = navigator.language.startsWith("en") ? "en" : navigator.language;
        const language = getDefaultLang() ?? browserLanguage;

        const selectedLanguage =
            LANGUAGE_CONFIGURATION.availableLanguages.find((availableLanguage) => availableLanguage === language) ??
            LANGUAGE_CONFIGURATION.defaultLanguage;

        store.dispatch(new UIActions.SetActiveLanguage(selectedLanguage));

        return lastValueFrom(translocoService.load(selectedLanguage));
    };
}
