import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { CourseData, CourseDataType, CourseStatus } from "../../models/elearning.model";
import { CourseTag } from "../../services/elearning-api.service.converters";
import { getTagIcon } from "../../utils/get-tag-icon";

interface CourseCardComponentState {
    course: CourseData | undefined;
}

@UntilDestroy()
@Component({
    selector: "elearning-lib-course-card[course]",
    templateUrl: "./course-card.component.html",
    styleUrls: ["./course-card.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class CourseCardComponent {
    protected readonly CourseStatus = CourseStatus;
    protected readonly CourseDataType = CourseDataType;
    protected readonly getTagIcon = getTagIcon;

    protected readonly course$ = this.localStore.selectByKey("course");

    @Input() public set course(value: CourseData) {
        this.localStore.patchState({ course: value });
    }

    @Output() public readonly goToCourse = new EventEmitter<CourseData>();

    constructor(private readonly localStore: LocalComponentStore<CourseCardComponentState>) {
        this.localStore.setState({
            course: undefined,
        });
    }

    protected convertToISOString(date: Date): string {
        return date.toISOString();
    }

    protected isCourse(tags: CourseTag[]) {
        return !tags.length || tags.includes(CourseTag.Theory);
    }
}
