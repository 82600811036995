import { Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { RouterStateSnapshot, TitleStrategy } from "@angular/router";
import { TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import { ViewData } from "./models/elearning.model";

@Injectable({ providedIn: "root" })
export class ElearningPageTitleStrategy extends TitleStrategy {
    constructor(private readonly title: Title, private readonly translationHelper: TranslationHelperService) {
        super();
    }

    public override updateTitle(routerState: RouterStateSnapshot) {
        const titleData = (routerState.root.firstChild?.data?.viewData as ViewData | undefined)?.titleData;
        const translatedTitle = titleData && this.translationHelper.tryTranslate("eLearningLib.title", "-", titleData);

        if (translatedTitle) {
            this.title.setTitle(translatedTitle);
        }
    }
}
