import { DOCUMENT } from "@angular/common";
import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { DeviceSize, DeviceSizeService, UIActions, UIState } from "@dtm-frontend/shared/ui";
import { APPROX_DAYS_IN_YEAR, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { CookieService } from "ngx-cookie-service";
import { combineLatest, delay, filter, fromEvent, map, pairwise, takeUntil } from "rxjs";

interface AppComponentState {
    isCookiesInfoVisible: boolean;
    isScrollToTopButtonVisible: boolean;
    lastScrollTime: Date;
}

const E_LEARNING_COOKIE = "E_LEARNING_COOKIE";
const E_LEARNING_COOKIE_VALUE = "true";
const HIDE_SCROLL_BUTTON_DELAY = 5000;
const MIN_SCROLL_HEIGHT_FOR_BUTTON = 100;

@UntilDestroy()
@Component({
    selector: "elearning-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class AppComponent {
    protected readonly activeLanguage$ = this.store.select(UIState.activeLanguage).pipe(RxjsUtils.filterFalsy());
    protected readonly isCookiesInfoVisible$ = this.localStore.selectByKey("isCookiesInfoVisible");
    protected readonly isMenuClosed$ = this.store.select(UIState.isMenuCollapsed);
    protected readonly isMobile$ = this.deviceSizeService.getSizeObservable(DeviceSize.Smartphone, DeviceSize.SmartphoneWide);
    protected readonly isDesktop$ = this.deviceSizeService.getSizeObservable(DeviceSize.Desktop, DeviceSize.DesktopWide);
    protected readonly isScrollToTopButtonVisible$ = this.localStore.selectByKey("isScrollToTopButtonVisible");

    constructor(
        private readonly store: Store,
        private readonly localStore: LocalComponentStore<AppComponentState>,
        private readonly cookieService: CookieService,
        private readonly deviceSizeService: DeviceSizeService,
        @Inject(DOCUMENT) private document: Document
    ) {
        localStore.setState({
            isCookiesInfoVisible: !cookieService.check(E_LEARNING_COOKIE),
            isScrollToTopButtonVisible: false,
            lastScrollTime: new Date(),
        });

        combineLatest([
            this.isMenuClosed$,
            this.deviceSizeService.getSizeObservable(DeviceSize.Tablet, DeviceSize.Desktop, DeviceSize.DesktopWide),
        ])
            .pipe(untilDestroyed(this))
            .subscribe(([isMenuClosed, isDesktopSize]) => {
                if (!isMenuClosed && isDesktopSize) {
                    this.store.dispatch(new UIActions.SetMenuCollapsedState(true));
                }
            });

        this.isDesktop$.pipe(untilDestroyed(this)).subscribe((isDesktop) => {
            if (isDesktop) {
                this.removeScrollToTopButton();
            } else {
                this.addScrollToTopButton();
            }
        });
    }

    protected hideCookiesInfo() {
        this.cookieService.set(E_LEARNING_COOKIE, E_LEARNING_COOKIE_VALUE, { expires: APPROX_DAYS_IN_YEAR });
        this.localStore.patchState({ isCookiesInfoVisible: false });
    }

    protected showCookiesInfo() {
        this.localStore.patchState({ isCookiesInfoVisible: true });
    }

    protected closeSidebar() {
        this.store.dispatch(new UIActions.SetMenuCollapsedState(true));
    }

    protected scrollToTop() {
        this.document.scrollingElement?.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }

    private removeScrollToTopButton() {
        this.localStore.patchState({ isScrollToTopButtonVisible: false });
    }

    private addScrollToTopButton() {
        fromEvent(this.document, "scroll")
            .pipe(
                map(() => this.document.scrollingElement?.scrollTop ?? 0),
                pairwise(),
                takeUntil(this.isDesktop$.pipe(RxjsUtils.filterFalsy())),
                map(([previousValue, currentValue]) => {
                    if (currentValue < MIN_SCROLL_HEIGHT_FOR_BUTTON) {
                        this.localStore.patchState({ isScrollToTopButtonVisible: false });

                        return;
                    }

                    if (previousValue > currentValue) {
                        this.localStore.patchState({
                            isScrollToTopButtonVisible: currentValue > MIN_SCROLL_HEIGHT_FOR_BUTTON,
                            lastScrollTime: new Date(),
                        });
                    }
                }),
                untilDestroyed(this)
            )
            .subscribe();

        fromEvent(this.document, "scrollend")
            .pipe(
                delay(HIDE_SCROLL_BUTTON_DELAY),
                filter(() => this.localStore.selectSnapshotByKey("isScrollToTopButtonVisible")),
                filter(
                    () => new Date().getTime() - this.localStore.selectSnapshotByKey("lastScrollTime").getTime() > HIDE_SCROLL_BUTTON_DELAY
                ),
                takeUntil(this.isDesktop$.pipe(RxjsUtils.filterFalsy())),
                untilDestroyed(this)
            )
            .subscribe(() => {
                this.localStore.patchState({ isScrollToTopButtonVisible: false });
            });
    }
}
