import { DOCUMENT } from "@angular/common";
import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UntilDestroy } from "@ngneat/until-destroy";
import { map } from "rxjs";
import { MAIN_PAGE_APP_URL } from "../../elearning.tokens";
import { CatalogPageViewData, CourseData } from "../../models/elearning.model";

@UntilDestroy()
@Component({
    templateUrl: "./catalog-page.component.html",
    styleUrls: ["./catalog-page.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CatalogPageComponent {
    protected readonly viewData$ = this.route.data.pipe(map(({ viewData }) => viewData as CatalogPageViewData));

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        @Inject(MAIN_PAGE_APP_URL) private readonly mainPageAppUrl: string,
        @Inject(DOCUMENT) private readonly document: Document
    ) {}

    protected goToCheckUavAppDetails() {
        this.document.defaultView?.open(this.mainPageAppUrl, "_blank");
    }

    protected goToHelp() {
        console.error("Not implemented yet!");
    }

    protected goToCourse(course: CourseData) {
        this.router.navigate([course.link]);
    }
}
