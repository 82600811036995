<ng-container [ngSwitch]="true">
    <ng-container *ngSwitchCase="isMobile$ | ngrxPush">
        <ng-container *ngTemplateOutlet="carousel; context: { $implicit: 1 }"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="isTablet$ | ngrxPush">
        <ng-container *ngTemplateOutlet="carousel; context: { $implicit: 2 }"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="isSmallDesktop$ | ngrxPush">
        <ng-container *ngTemplateOutlet="carousel; context: { $implicit: 3 }"></ng-container>
    </ng-container>
    <ng-container *ngSwitchDefault>
        <ng-container *ngTemplateOutlet="carousel; context: { $implicit: 4 }"></ng-container>
    </ng-container>
</ng-container>

<ng-template #carousel let-count>
    <elearning-lib-angular-carousel
        [bullets]="false"
        *ngIf="slides$ | ngrxPush | invoke : getPartitionedSlides : count as slides"
        #carouselComponent
        [nextButtonAriaLabel]="'eLearningLib.carouselNextAriaLabel' | transloco"
        [previousButtonAriaLabel]="'eLearningLib.carouselPrevAriaLabel' | transloco"
    >
        <ng-container *ngFor="let slide of slides; let index = index">
            <div class="slide" *eLearningLibAngularCarouselSlide [attr.inert]="carouselComponent.currentSlide !== index || null">
                <ng-container *ngFor="let card of slide">
                    <elearning-lib-course-card [course]="card" (goToCourse)="goToCourse.emit($event)"></elearning-lib-course-card>
                </ng-container>
            </div>
        </ng-container>
    </elearning-lib-angular-carousel>
</ng-template>
