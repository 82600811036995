/* eslint-disable no-underscore-dangle */

import { MILLISECONDS_IN_SECOND } from "@dtm-frontend/shared/utils";
import { ELearningEndpoints } from "../elearning.tokens";
import {
    CatalogCoursesCategory,
    CourseCategoryType,
    CourseDataType,
    CourseDetailsPageViewData,
    CourseProgressPageViewData,
    CourseStatus,
    CourseSummaryPageViewData,
    CourseUnitStatus,
    CurrentCourseUnit,
    EnrollmentViewData,
    ExamPageViewData,
    QuizAnswersFormGroupValue,
    UnitType,
    UserCourseDetailsData,
} from "../models/elearning.model";
import { ExamAnswersFormGroupValue } from "../pages/exam-page/exam-page.component";
import { CatalogPageViewData } from "./../models/elearning.model";

export enum HateoasLinkRel {
    CourseDetails = "courseDetails",
    CourseDescription = "courseDescription",
    Self = "self",
    Lesson = "lesson",
    Quiz = "quiz",
    ExamDescription = "examDescription",
    AnswerQuizQuestions = "answerQuizQuestions",
    StartExam = "startExam",
    ExamQuestion = "examQuestion",
    AnswerExamQuestion = "answerExamQuestion",
    ExamResult = "examResult",
    Catalog = "catalog",
    Enroll = "enroll",
    FinishExam = "finishExam",
}

interface HateoasLink {
    href: string;
    rel: HateoasLinkRel;
    type: "GET" | "POST";
}

interface AvailableCourseEntity {
    id: string;
    name: string;
    categoryName: string;
    briefDescription: string;
    categoryColor: string;
    tags: CourseTag[];
    _links: HateoasLink[];
}

export enum CourseTag {
    Exam = "exam",
    Theory = "theory",
}

interface ProgressEntity {
    id: string;
    unitType: UnitType;
    unitNumber: number;
    completionPercentage: number;
    _links: HateoasLink[];
}

interface QuizAnswersRequestPayload {
    questionAnswers: {
        question: number;
        answers: number[];
    }[];
}

interface ExamAnswersRequestPayload {
    answers: number[];
}

export interface CatalogPageViewDataResponseBody {
    userCourses: {
        id: string;
        name: string;
        categoryName: string;
        status: CourseStatus;
        progress: ProgressEntity | null;
        finishedAt: string | null;
        _links: HateoasLink[];
    }[];
    categories: {
        name: string;
        priority: number;
        color: string;
        availableCourses: AvailableCourseEntity[];
    }[];
    _links: HateoasLink[];
}

export interface CourseDetailsViewDataResponseBody {
    id: string;
    categoryColor: string;
    name: string;
    title: string;
    tags: CourseTag[];
    description: string;
    requirementsMeet: boolean;
    requiredCourses: AvailableCourseEntity[];
    inProgress: boolean;
    progress?: ProgressEntity;
    _links: HateoasLink[];
}

export interface CourseEnrollmentViewDataResponseBody {
    unitType: UnitType;
    unitNumber: number;
    _links: HateoasLink[];
}

interface NavigationUnitEntity {
    number: number;
    type: UnitType;
    _links: HateoasLink[];
}

export interface CourseUnitEntity {
    visited: boolean;
    current: boolean;
    type: UnitType;
    number: number;
    name: string;
    _links: HateoasLink[] | null;
}

export interface CourseChapterEntity {
    name: string;
    units: CourseUnitEntity[];
}

interface CourseProgressViewDataResponseBodyBase {
    courseName: string;
    completionPercentage: number;
    categoryColor: string;
    tags: CourseTag[];
    next: NavigationUnitEntity | null;
    prev: NavigationUnitEntity | null;
    actualTableOfContent: {
        chapters: CourseChapterEntity[];
    };
    _links: HateoasLink[];
}

export interface LessonViewDataResponseBody extends CourseProgressViewDataResponseBodyBase {
    content: string;
}

export interface CourseSummaryViewDataResponseBody {
    content: string;
    courseName: string;
    tags: CourseTag[];
    categoryColor: string;
    _links: HateoasLink[];
}

export interface ExamDescriptionViewDataResponseBody extends CourseProgressViewDataResponseBodyBase {
    content: string;
}

export interface QuizViewDataResponseBody extends CourseProgressViewDataResponseBodyBase {
    questions: {
        number: number;
        content: string;
        allowedMultipleAnswers: boolean;
        availableAnswers: {
            number: number;
            content: string;
        }[];
    }[];
}

export interface ExamQuestionViewDataResponseBody {
    categoryColor: string;
    courseName: string;
    tags: CourseTag[];
    timeLimitInSeconds: number;
    timeLeftInSeconds: number;
    currentQuestion: number;
    maxQuestions: number;
    question: {
        number: number;
        allowedMultipleAnswers: boolean;
        content: string;
        availableAnswers: {
            number: number;
            content: string;
        }[];
    } | null;
    lastQuestion: boolean;
    _links: HateoasLink[];
}

export interface ExamResultViewDataResponseBody {
    categoryColor: string;
    courseName: string;
    tags: CourseTag[];
    passed: boolean;
    percentageAccuracy: number;
    content: string;
    _links: HateoasLink[];
}

export type QuizResultsResponseBody = QuizViewDataResponseBody & {
    passed: boolean;
    percentageAccuracy: number;
    questionsResults: {
        passed: boolean;
        question: number;
        answerResults: {
            number: number;
            correct: boolean;
            markedByUser: boolean;
            passed: boolean;
        }[];
    }[];
    next: NavigationUnitEntity | null;
    prev: NavigationUnitEntity | null;
    _links: HateoasLink[];
};

export class ElearningApiServiceConverters {
    constructor(private readonly endpoints: ELearningEndpoints) {}

    private static getCategoryTypeForColor(color: string): CourseCategoryType {
        switch (color) {
            case "blue":
                return CourseCategoryType.First;
            case "yellow":
                return CourseCategoryType.Second;
            default:
                return CourseCategoryType.Other;
        }
    }

    private extractLocalLinkFromHateoasLink(hateoasLink: HateoasLink | undefined): string | undefined {
        if (!hateoasLink) {
            return undefined;
        }

        return hateoasLink.href.replace(this.endpoints.rootUri, "");
    }

    public convertCatalogPageViewDataResponseBodyToViewData(responseBody: CatalogPageViewDataResponseBody): CatalogPageViewData {
        const categories = responseBody.categories
            .sort((left, right) => left.priority - right.priority)
            .map(
                (category): CatalogCoursesCategory => ({
                    type: ElearningApiServiceConverters.getCategoryTypeForColor(category.color),
                    name: category.name,
                    courses: category.availableCourses.map((course) => ({
                        id: course.id,
                        type: CourseDataType.CourseDetails,
                        category: {
                            name: course.categoryName,
                            type: ElearningApiServiceConverters.getCategoryTypeForColor(category.color),
                            tags: course.tags,
                        },
                        header: course.name,
                        description: course.briefDescription,
                        link: this.extractLocalLinkFromHateoasLink(course._links.find((link) => link.rel === HateoasLinkRel.CourseDetails)),
                    })),
                })
            );

        const userCourses = responseBody.userCourses.map((course): UserCourseDetailsData => {
            const category = categories.find((lookup) => lookup.name === course.categoryName);

            return {
                id: course.id,
                type: CourseDataType.UserCourseDetails,
                category: {
                    name: course.categoryName,
                    type: category?.type ?? CourseCategoryType.Other,
                    tags: category?.courses.find((lookup) => lookup.id === course.id)?.category.tags ?? [],
                },
                header: course.name,
                progress: course.progress ?? {
                    completionPercentage: 100,
                },
                status: course.status,
                finishedAt: course.finishedAt ? new Date(course.finishedAt) : undefined,
                link:
                    this.extractLocalLinkFromHateoasLink(course.progress?._links.find((link) => link.rel !== HateoasLinkRel.Self)) ??
                    this.extractLocalLinkFromHateoasLink(course._links.find((link) => link.rel === HateoasLinkRel.CourseDetails)),
            };
        });

        return {
            titleData: {
                type: "catalog",
            },
            userCourses,
            categories,
        };
    }

    public convertCourseDetailsViewDataResponseBodyToViewData(responseBody: CourseDetailsViewDataResponseBody): CourseDetailsPageViewData {
        return {
            titleData: { type: "details", name: responseBody.name },
            courseId: responseBody.id,
            name: responseBody.name,
            categoryType: ElearningApiServiceConverters.getCategoryTypeForColor(responseBody.categoryColor),
            description: {
                header: responseBody.title,
                textHtml: responseBody.description,
            },
            requirements: responseBody.requirementsMeet
                ? []
                : responseBody.requiredCourses.map((course) => ({
                      id: course.id,
                      name: course.name,
                      description: course.briefDescription,
                      type: CourseDataType.CourseDetails,
                      header: course.name,
                      category: {
                          name: course.categoryName,
                          type: ElearningApiServiceConverters.getCategoryTypeForColor(responseBody.categoryColor),
                          tags: course.tags,
                      },
                      link: this.extractLocalLinkFromHateoasLink(course._links.find((link) => link.rel === HateoasLinkRel.CourseDetails)),
                  })),
            tags: responseBody.tags,
            progress: responseBody.progress,
            previousLink: this.extractLocalLinkFromHateoasLink(responseBody._links.find((link) => link.rel === HateoasLinkRel.Catalog)),
            nextLink:
                this.extractLocalLinkFromHateoasLink(responseBody.progress?._links.find((link) => link.rel !== HateoasLinkRel.Self)) ??
                this.extractLocalLinkFromHateoasLink(responseBody._links.find((link) => link.rel === HateoasLinkRel.Enroll)),
        };
    }

    public convertCourseEnrollmentViewDataResponseBodyToViewData(responseBody: CourseEnrollmentViewDataResponseBody): EnrollmentViewData {
        return {
            titleData: { type: "enrollment" },
            nextLink: this.extractLocalLinkFromHateoasLink(responseBody._links[0]),
        };
    }

    private convertCourseProgressViewDataResponseBodyBaseToViewData(
        responseBody: CourseProgressViewDataResponseBodyBase,
        currentUnitConverter: (unit: CourseUnitEntity, chapter: CourseChapterEntity) => CurrentCourseUnit | undefined
    ): CourseProgressPageViewData {
        let currentUnit: CurrentCourseUnit | undefined;

        for (let index = 0; index < responseBody.actualTableOfContent.chapters.length; index++) {
            const chapter = responseBody.actualTableOfContent.chapters[index];

            for (let unitIndex = 0; unitIndex < chapter.units.length; unitIndex++) {
                const unit = chapter.units[unitIndex];

                if (unit.current) {
                    currentUnit = currentUnitConverter(unit, chapter);
                    break;
                }
            }
        }

        if (!currentUnit) {
            throw new Error("Current unit not found");
        }

        return {
            titleData: {
                type: "progress",
                name: responseBody.courseName,
                unitName: currentUnit.title,
                chapterName: currentUnit.chapterTitle,
            },
            name: responseBody.courseName,
            categoryType: ElearningApiServiceConverters.getCategoryTypeForColor(responseBody.categoryColor),
            tags: responseBody.tags,
            description: {
                header: responseBody.courseName,
                descriptionLink: responseBody._links.find((link) => link.rel === HateoasLinkRel.CourseDescription)?.href,
            },
            chapters: responseBody.actualTableOfContent.chapters.map((chapter) => ({
                title: chapter.name,
                units: chapter.units.map((unit) => ({
                    title: unit.name,
                    type: unit.type,
                    status: unit.current ? CourseUnitStatus.Active : unit.visited ? CourseUnitStatus.Visited : CourseUnitStatus.Unvisited,
                    link: this.extractLocalLinkFromHateoasLink(unit._links?.[0]),
                })),
            })),
            progress: {
                completionPercentage: responseBody.completionPercentage,
            },
            currentUnit,
            previousLink: this.extractLocalLinkFromHateoasLink(responseBody.prev?._links[0]),
            nextLink: this.extractLocalLinkFromHateoasLink(responseBody.next?._links[0]),
            isNextLinkSummary: responseBody.next?.type === UnitType.Summary,
        };
    }

    public convertLessonViewDataResponseBodyToViewData(responseBody: LessonViewDataResponseBody): CourseProgressPageViewData {
        const result = this.convertCourseProgressViewDataResponseBodyBaseToViewData(responseBody, (unit, chapter) => ({
            chapterTitle: chapter.name,
            title: unit.name,
            textHtml: responseBody.content,
            type: UnitType.Lesson,
        }));

        return result;
    }

    public convertCourseSummaryViewDataResponseBodyToViewData(responseBody: CourseSummaryViewDataResponseBody): CourseSummaryPageViewData {
        return {
            titleData: {
                type: "summary",
            },
            courseName: responseBody.courseName,
            tags: responseBody.tags,
            categoryType: ElearningApiServiceConverters.getCategoryTypeForColor(responseBody.categoryColor),
            unitType: UnitType.Summary,
            textHtml: responseBody.content,
            nextLink: this.extractLocalLinkFromHateoasLink(responseBody._links.find((link) => link.rel !== HateoasLinkRel.Self)),
        };
    }

    public convertExamDescriptionViewDataResponseBodyToViewData(responseBody: LessonViewDataResponseBody): CourseProgressPageViewData {
        const result = this.convertCourseProgressViewDataResponseBodyBaseToViewData(responseBody, (unit, chapter) => ({
            chapterTitle: chapter.name,
            title: unit.name,
            textHtml: responseBody.content,
            type: UnitType.ExamDescription,
        }));

        return {
            ...result,
            nextLink: this.extractLocalLinkFromHateoasLink(responseBody._links.find((link) => link.rel === HateoasLinkRel.StartExam)),
        };
    }

    public convertQuizViewDataResponseBodyToViewData(responseBody: QuizViewDataResponseBody): CourseProgressPageViewData {
        const result = this.convertCourseProgressViewDataResponseBodyBaseToViewData(responseBody, (unit, chapter) => ({
            chapterTitle: chapter.name,
            title: unit.name,
            type: UnitType.Quiz,
            quizData: responseBody.questions.map((question) => ({
                id: question.number,
                questionTextHtml: question.content,
                isMultipleChoice: question.allowedMultipleAnswers,
                answers: question.availableAnswers.map((answer) => ({
                    id: answer.number,
                    textHtml: answer.content,
                })),
            })),
        }));

        return {
            ...result,
            nextLink: this.extractLocalLinkFromHateoasLink(
                responseBody._links.find((link) => link.rel === HateoasLinkRel.AnswerQuizQuestions)
            ),
        };
    }

    public convertQuizAnswersFromGroupValueToQuizAnswersRequestPayload(answers: QuizAnswersFormGroupValue): QuizAnswersRequestPayload {
        return {
            questionAnswers: Object.entries(answers).map(([questionId, answersGroup]) => ({
                question: Number(questionId),
                answers: !answersGroup
                    ? []
                    : Object.entries(answersGroup)
                          .filter(([, answer]) => answer)
                          .map(([answerId]) => Number(answerId)),
            })),
        };
    }

    public convertExamAnswersFromGroupValueToExamAnswersRequestPayload(answers: ExamAnswersFormGroupValue): ExamAnswersRequestPayload {
        return {
            answers: Object.entries(answers)
                .filter(([, answer]) => answer)
                .map(([answerId]) => Number(answerId)),
        };
    }

    public convertQuizResultsResponseBodyIntoViewData(response: QuizResultsResponseBody): CourseProgressPageViewData {
        const courseProgressViewData = this.convertQuizViewDataResponseBodyToViewData(response);

        if (courseProgressViewData.currentUnit.type !== UnitType.Quiz) {
            throw new Error("Invalid current unit type");
        }

        return {
            ...courseProgressViewData,
            currentUnit: {
                ...courseProgressViewData.currentUnit,
                type: UnitType.QuizResults,
                quizData: courseProgressViewData.currentUnit.quizData.map((question) => ({
                    ...question,
                    answers: question.answers.map((answer) => {
                        const matchedResult = response.questionsResults
                            .find((questionResult) => questionResult.question === question.id)
                            ?.answerResults.find((answerResult) => answerResult.number === answer.id);

                        return {
                            ...answer,
                            wasSelected: matchedResult?.markedByUser,
                            isCorrect: matchedResult?.correct,
                        };
                    }),
                })),
                validityPercentage: response.percentageAccuracy,
                hasPassed: response.passed,
            },
            nextLink:
                this.extractLocalLinkFromHateoasLink(response.next?._links.find((link) => link.rel !== HateoasLinkRel.Self)) ??
                courseProgressViewData.nextLink,
            previousLink:
                this.extractLocalLinkFromHateoasLink(response.prev?._links.find((link) => link.rel !== HateoasLinkRel.Self)) ??
                courseProgressViewData.previousLink,
        };
    }

    public convertExamQuestionViewDataResponseBodyToViewData(response: ExamQuestionViewDataResponseBody): ExamPageViewData {
        return {
            titleData: {
                type: "exam",
                name: response.courseName,
            },
            type: UnitType.ExamQuestion,
            courseName: response.courseName,
            tags: response.tags,
            categoryType: ElearningApiServiceConverters.getCategoryTypeForColor(response.categoryColor),
            timeLeftSeconds: response.timeLeftInSeconds,
            timeLeftTimestamp: Date.now() + response.timeLeftInSeconds * MILLISECONDS_IN_SECOND,
            initialTimeSeconds: response.timeLimitInSeconds,
            currentQuestionIndex: response.currentQuestion,
            totalQuestionsCount: response.maxQuestions,
            question: !response.question
                ? undefined
                : {
                      id: response.question.number,
                      textHtml: response.question.content,
                      isMultipleChoice: response.question.allowedMultipleAnswers,
                      answers: response.question.availableAnswers.map((answer) => ({
                          id: answer.number,
                          textHtml: answer.content,
                      })),
                  },
            finishLink: this.extractLocalLinkFromHateoasLink(response._links.find((link) => link.rel === HateoasLinkRel.FinishExam)),
            nextLink: this.extractLocalLinkFromHateoasLink(response._links.find((link) => link.rel !== HateoasLinkRel.Self)),
        };
    }

    public convertExamResultViewDataResponseBodyToViewData(response: ExamResultViewDataResponseBody): ExamPageViewData {
        return {
            titleData: {
                type: "exam",
                name: response.courseName,
            },
            type: UnitType.Summary,
            tags: response.tags,
            courseName: response.courseName,
            categoryType: ElearningApiServiceConverters.getCategoryTypeForColor(response.categoryColor),
            hasPassed: response.passed,
            percentage: response.percentageAccuracy,
            textHtml: response.content,
            nextLink: this.extractLocalLinkFromHateoasLink(response._links.find((link) => link.rel !== HateoasLinkRel.Self)),
        };
    }
}
