<div class="carousel__container">
    <div class="carousel__slider">
        <div *ngIf="infinite || currentSlide > 0" class="carousel__previous">
            <button (click)="previousSlide($event)" type="button" [ariaLabel]="previousButtonAriaLabel">
                <svg viewBox="0 0 20 20">
                    <polyline
                        points="12 2, 7 10, 12 18"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        fill="transparent"
                        stroke-linejoin="round"
                    ></polyline>
                </svg>
            </button>
        </div>
        <ol [style.left.%]="currentSlide * -100" class="carousel__slides" (mouseenter)="stopInterval()" (mouseleave)="startInterval()">
            <li
                *ngFor="let slide of slides; let index = index"
                [ngClass]="{ 'carousel__slide--active': index === currentSlide }"
                class="carousel__slide"
            >
                <ng-container [ngTemplateOutlet]="slide.template"></ng-container>
            </li>
        </ol>
        <div *ngIf="infinite || currentSlide < slides.length - 1" class="carousel__next">
            <button (click)="nextSlide($event)" type="button" [ariaLabel]="nextButtonAriaLabel">
                <svg viewBox="0 0 20 20">
                    <polyline
                        points="8 2, 13 10, 8 18"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        fill="transparent"
                        stroke-linejoin="round"
                    ></polyline>
                </svg>
            </button>
        </div>
    </div>
    <ng-container *ngIf="bullets === undefined || bullets">
        <ol class="carousel__bullets">
            <li *ngFor="let slide of slides; let index = index">
                <button
                    type="button"
                    (click)="goToSlide(index + 1, $event)"
                    [ngClass]="{ 'carousel__bullet--active': index === currentSlide }"
                    class="carousel__bullet"
                >
                    <span class="sr-only">{{ index + 1 }}</span>
                </button>
            </li>
        </ol>
    </ng-container>
</div>
