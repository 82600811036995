import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ELearningRoutingModule } from "@dtm-frontend/elearning-client-lib";
import { DTM_AUTH_GUARD_REQUIRED_ROLES, DtmAuthGuard, NOT_AUTHORIZED_ROUTE } from "@dtm-frontend/shared/auth";
import { AdminPanelComponent } from "./components/admin-panel/admin-panel.component";
import { NotAuthorizedComponent } from "./components/not-authorized/not-authorized.component";

const routes: Routes = [
    {
        path: NOT_AUTHORIZED_ROUTE,
        component: NotAuthorizedComponent,
        canActivate: [DtmAuthGuard],
    },
    { path: "admin", component: AdminPanelComponent }, // TODO: REJ-1885 Add guard
    { path: "**", redirectTo: "" },
];

@NgModule({
    imports: [RouterModule.forRoot(routes), ELearningRoutingModule],
    exports: [RouterModule],
    providers: [
        {
            provide: DTM_AUTH_GUARD_REQUIRED_ROLES,
            useValue: ["dtm-user"],
        },
    ],
})
export class AppRoutingModule {}
