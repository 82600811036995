import { addDays } from "./add-days";
import { addHours } from "./add-hours";
import { addMinutes } from "./add-minutes";
import { calculateDiffDate } from "./calculate-diff-date";
import { compareDates } from "./compare-dates";
import { convertStringDateToDate } from "./convert-string-to-date-without-time-zone";
import { daysBetween } from "./days-between";
import { convertDateToFakeUTC, convertFakeUTCToDate } from "./fake-utc-converters";
import { getISOStringDate } from "./get-iso-string-date";
import { getISOStringTime } from "./get-iso-string-time";
import { convertFromSeconds } from "./iso8601-duration/convert-from-seconds";
import { convertToSeconds } from "./iso8601-duration/convert-to-seconds";
import { extract } from "./iso8601-duration/extract";
import { getFinishDate } from "./iso8601-duration/get-finish-date";
import { minutesBetween } from "./minutes-between";
import { selectEarlierDate } from "./select-earlier-date";

export * from "./dates-diff.pipe";
export { FakeUTCDate } from "./fake-utc-converters";
export * from "./iso8601-duration/models";

export const DateUtils = {
    addDays,
    addHours,
    addMinutes,
    calculateDiffDate,
    convertSecondsToISO8601Duration: convertFromSeconds,
    convertISO8601DurationToSeconds: convertToSeconds,
    extractISO8601Duration: extract,
    convertFakeUTCToDate,
    convertDateToFakeUTC,
    getFinishDateForISO8601Duration: getFinishDate,
    getISOStringDate: getISOStringDate,
    getISOStringTime,
    compareDates,
    selectEarlierDate,
    convertStringDateToDateWithoutTimeZone: convertStringDateToDate,
    daysBetween: daysBetween,
    minutesBetween: minutesBetween,
};
