import { Inject, Injectable, InjectionToken } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Actions, Store, ofActionDispatched } from "@ngxs/store";
import { SoundDefinition, SoundType } from "../../models/sounds.models";
import { SoundsActions } from "../../state/sounds.actions";

export const SOUND_DEFINITIONS_TOKEN = new InjectionToken<SoundDefinition>("SOUND_DEFINITIONS");

@UntilDestroy()
@Injectable({
    providedIn: "root",
})
export class SoundService {
    private soundList: Map<SoundType, HTMLAudioElement> = new Map();

    constructor(
        private readonly actions$: Actions,
        private readonly store: Store,
        @Inject(SOUND_DEFINITIONS_TOKEN) private readonly soundDefinition: SoundDefinition
    ) {
        this.actions$
            .pipe(ofActionDispatched(SoundsActions.PlaySound), untilDestroyed(this))
            .subscribe(({ soundType }) => this.playSound(soundType));

        this.actions$
            .pipe(ofActionDispatched(SoundsActions.StopSound), untilDestroyed(this))
            .subscribe(({ soundType }) => this.pauseSound(soundType));
    }

    private playSound(soundType: SoundType): void {
        if (!this.soundDefinition[soundType]) {
            return;
        }

        const audioElement = this.soundList.get(soundType);
        if (!audioElement) {
            const newAudioElement = new Audio(this.soundDefinition[soundType]);
            this.soundList.set(soundType, newAudioElement);
            newAudioElement.addEventListener("canplaythrough", this.canPlayThroughEventListenerWithPlayFactory(newAudioElement, soundType));

            return;
        }
        if (audioElement.readyState === HTMLMediaElement.HAVE_ENOUGH_DATA) {
            audioElement.play();

            return;
        }
        audioElement.addEventListener("canplaythrough", this.canPlayThroughEventListenerWithPlayFactory(audioElement, soundType));
    }

    private pauseSound(soundType: SoundType): void {
        if (!this.soundList.has(soundType)) {
            return;
        }

        this.soundList.get(soundType)?.pause();
    }

    private canPlayThroughEventListenerWithPlayFactory(audio: HTMLAudioElement, soundType: SoundType) {
        const listenerRef = () => {
            audio.play().catch((error) => {
                // NOTE: This is to handle issue with browsers autoplay policy
                // https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/play#notallowederror
                console.error("Error playing audio", error);
                this.store.dispatch(new SoundsActions.SoundPlayError(soundType, error));
            });

            audio.removeEventListener("canplaythrough", listenerRef);
        };

        return listenerRef;
    }
}
