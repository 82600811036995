import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { MatLegacyProgressBarModule as MatProgressBarModule } from "@angular/material/legacy-progress-bar";
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from "@angular/material/legacy-progress-spinner";
import { MatLegacySlideToggleModule as MatSlideToggleModule } from "@angular/material/legacy-slide-toggle";
import { RouterModule } from "@angular/router";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { getTranslocoInlineLoader, LanguageCode, SharedI18nModule } from "@dtm-frontend/shared/ui/i18n";
import { TRANSLOCO_SCOPE, TranslocoService } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { QUILL_CONFIG_TOKEN, QuillModule } from "ngx-quill";
import { BypassSanitizePipe } from "./bypass-sanitizer.pipe";
import { AngularCarouselComponent } from "./components/angular-carousel/carousel.component";
import { AngularCarouselSlideDirective } from "./components/angular-carousel/slide.directive";
import { CourseCardComponent } from "./components/course-card/course-card.component";
import { CourseCardsCarouselComponent } from "./components/course-cards-carousel/course-cards-carousel.component";
import { ExtendedQuillEditorComponent } from "./components/extended-quill-editor/extended-quill-editor.component";
import { HeaderUserButtonComponent } from "./components/header-user-button/header-user-button.component";
import { QuestionAnswerComponent } from "./components/question-answer/question-answer.component";
import { QuizResultCardComponent } from "./components/quiz-result-card/quiz-result-card.component";
import { ELearningRoutingModule } from "./elearning-routing.module";
import { CatalogPageComponent } from "./pages/catalog-page/catalog-page.component";
import { CourseDetailsPageComponent } from "./pages/course-details-page/course-details-page.component";
import { CourseNotAllowedComponent } from "./pages/course-not-allowed/course-not-allowed.component";
import { CourseProgressPageComponent } from "./pages/course-progress-page/course-progress-page.component";
import { CourseSummaryPageComponent } from "./pages/course-summary-page/course-summary-page.component";
import { ExamPageComponent } from "./pages/exam-page/exam-page.component";
import { ElearningApiService } from "./services/elearning-api.service";
import { getQuillConfig } from "./utils/quill-config";

@NgModule({
    imports: [
        CommonModule,
        ELearningRoutingModule,
        LetModule,
        MatInputModule,
        MatMenuModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatSlideToggleModule,
        PushModule,
        ReactiveFormsModule,
        RouterModule,
        SharedI18nModule,
        SharedUiModule,
        RouterModule,
        MatProgressBarModule,
        QuillModule,
        AngularCarouselComponent,
        AngularCarouselSlideDirective,
    ],
    declarations: [
        CatalogPageComponent,
        CourseCardComponent,
        HeaderUserButtonComponent,
        CourseCardsCarouselComponent,
        BypassSanitizePipe,
        QuestionAnswerComponent,
        ExtendedQuillEditorComponent,
        QuizResultCardComponent,
        CourseDetailsPageComponent,
        CourseProgressPageComponent,
        ExamPageComponent,
        CourseSummaryPageComponent,
        CourseNotAllowedComponent,
    ],
    exports: [
        HeaderUserButtonComponent,
        CourseCardComponent,
        CourseCardsCarouselComponent,
        QuestionAnswerComponent,
        ExtendedQuillEditorComponent,
        QuizResultCardComponent,
    ],
    providers: [
        ElearningApiService,
        { provide: QUILL_CONFIG_TOKEN, useFactory: getQuillConfig, deps: [TranslocoService] },
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "eLearningLib",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`../assets/i18n/${language}.json`)),
            },
        },
    ],
})
export class ELearningModule {}
