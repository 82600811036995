import { ChangeDetectionStrategy, Component, forwardRef, Input } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";

interface DarkModeToggleComponentState {
    isDarkMode: boolean;
}

@Component({
    selector: "dtm-ui-dark-mode-toggle",
    templateUrl: "./dark-mode-toggle.component.html",
    styleUrls: ["./dark-mode-toggle.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DarkModeToggleComponent),
            multi: true,
        },
        LocalComponentStore,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DarkModeToggleComponent implements ControlValueAccessor {
    @Input() public set isDarkMode(value: boolean) {
        this.localStore.patchState({ isDarkMode: value });
    }

    public isDarkMode$ = this.localStore.selectByKey("isDarkMode");

    constructor(private readonly localStore: LocalComponentStore<DarkModeToggleComponentState>) {
        this.localStore.setState({ isDarkMode: false });
    }

    public onChange = (isDarkMode: boolean) => {};
    public onTouched = () => {};

    public writeValue(isDarkMode: boolean): void {
        this.isDarkMode = isDarkMode;
    }

    public registerOnChange(fn: (isDarkMode: boolean) => void): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    protected toggleDarkMode(): void {
        this.localStore.patchState((state) => ({
            isDarkMode: !state.isDarkMode,
        }));
        this.onChange(this.localStore.selectSnapshotByKey("isDarkMode"));
    }
}
