import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({ name: "eLearningBypassSanitizer" })
export class BypassSanitizePipe implements PipeTransform {
    constructor(private sanitized: DomSanitizer) {}

    public transform(value: string) {
        return this.sanitized.bypassSecurityTrustHtml(value);
    }
}
