import { ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";

interface AdminForm {
    html: FormControl<string>;
}

interface ExtendedQuillEditorComponentState {
    tableHeaders: string[];
    tableData: string[][];
}

@Component({
    selector: "elearning-lib-extended-quill-editor",
    templateUrl: "./extended-quill-editor.component.html",
    styleUrls: ["./extended-quill-editor.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ExtendedQuillEditorComponent implements OnInit {
    @ViewChild("table", { static: true }) private table!: ElementRef;

    protected htmlFormControl = new FormControl<string>("", { nonNullable: true });
    protected readonly formGroup = new FormGroup<AdminForm>({
        html: this.htmlFormControl,
    });
    protected tableHeaders$ = this.localStore.selectByKey("tableHeaders");
    protected tableData$ = this.localStore.selectByKey("tableData");

    constructor(private readonly localStore: LocalComponentStore<ExtendedQuillEditorComponentState>) {
        this.localStore.setState({ tableHeaders: [], tableData: [[]] });
    }

    public ngOnInit(): void {
        this.addRow();
        this.addColumn();
    }

    public get result(): string {
        const table = (this.table.nativeElement.outerHTML as string).replace(/contenteditable="true" /g, "");

        return `<div class="elearning-course-card">${this.htmlFormControl.value.replace("%TABLE%", table)}</div>`;
    }

    protected addRow(): void {
        this.localStore.patchState((state) => ({ tableData: [...state.tableData, new Array(state.tableHeaders.length).fill("")] }));
    }

    protected addColumn(): void {
        this.localStore.patchState((state) => ({
            tableHeaders: [...state.tableHeaders, ""],
            tableData: [
                ...state.tableData.map((row) => {
                    row.push("");

                    return row;
                }),
            ],
        }));
    }

    protected removeLastRow(): void {
        this.localStore.patchState((state) => {
            state.tableData.pop();

            return { tableData: state.tableData };
        });
    }

    protected removeLastColumn(): void {
        this.localStore.patchState((state) => {
            state.tableHeaders.pop();

            return {
                tableHeaders: state.tableHeaders,
                tableData: [
                    ...state.tableData.map((row) => {
                        row.pop();

                        return row;
                    }),
                ],
            };
        });
    }
}
