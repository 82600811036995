<ng-container *ngrxLet="viewData$ as catalogViewData">
    <section class="user-courses" *ngIf="catalogViewData.userCourses.length > 0">
        <h2>{{ "eLearningLib.catalogPage.userCoursesHeader" | transloco }}</h2>
        <div class="cards">
            <elearning-lib-course-cards-carousel (goToCourse)="goToCourse($event)" [cards]="catalogViewData.userCourses">
            </elearning-lib-course-cards-carousel>
        </div>
    </section>

    <section class="explainer">
        <h2>{{ "eLearningLib.catalogPage.explainerSection.header" | transloco }}</h2>

        <div class="cards">
            <div class="explainer-card">
                <img src="assets/images/gamepad.svg" alt="" />
                <h3>{{ "eLearningLib.catalogPage.explainerSection.recreationalUsers.header" | transloco }}</h3>
                <p>{{ "eLearningLib.catalogPage.explainerSection.recreationalUsers.description" | transloco }}</p>
            </div>
            <div class="explainer-card">
                <img src="assets/images/target.svg" alt="" />
                <h3>{{ "eLearningLib.catalogPage.explainerSection.professionalUsers.header" | transloco }}</h3>
                <p>{{ "eLearningLib.catalogPage.explainerSection.professionalUsers.description" | transloco }}</p>
            </div>
            <div class="explainer-card">
                <img src="assets/images/drone.svg" alt="" />
                <h3>{{ "eLearningLib.catalogPage.explainerSection.underageUsers.header" | transloco }}</h3>
                <p>{{ "eLearningLib.catalogPage.explainerSection.underageUsers.description" | transloco }}</p>
            </div>
            <div class="explainer-card">
                <img src="assets/images/enterprise.svg" alt="" />
                <h3>{{ "eLearningLib.catalogPage.explainerSection.enterpriseUsers.header" | transloco }}</h3>
                <p>{{ "eLearningLib.catalogPage.explainerSection.enterpriseUsers.description" | transloco }}</p>
            </div>
        </div>
    </section>

    <section
        class="courses-section"
        [attr.id]="isFirst && 'course-sections-start'"
        *ngFor="let category of catalogViewData.categories; let isFirst = first"
        [ngClass]="'category-' + category.type"
    >
        <h2>{{ category.name }}</h2>
        <div class="cards">
            <elearning-lib-course-cards-carousel (goToCourse)="goToCourse($event)" [cards]="category.courses">
            </elearning-lib-course-cards-carousel>
        </div>
    </section>
</ng-container>

<section class="uav-app-splash">
    <div class="image">
        <img alt="" src="assets/images/uav-app-splash.png" />
    </div>
    <div class="text">
        <h2>{{ "eLearningLib.catalogPage.uavAppSplash.title" | transloco }}</h2>
        <p>{{ "eLearningLib.catalogPage.uavAppSplash.subtitle" | transloco }}</p>
    </div>
    <button type="button" class="button-primary action-button" (click)="goToCheckUavAppDetails()">
        {{ "eLearningLib.catalogPage.uavAppSplash.goToUavAppButtonLabel" | transloco }}
    </button>
</section>
