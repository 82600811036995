import { ChangeDetectionStrategy, Component } from "@angular/core";
import { AbstractControl, FormControl, FormGroup, ValidationErrors } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { MILLISECONDS_IN_HOUR, MILLISECONDS_IN_SECOND } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Observable, combineLatest, distinctUntilChanged, first, interval, map, shareReplay, switchMap } from "rxjs";
import { QuestionAnswerType } from "../../components/question-answer/question-answer.component";
import { ExamPageViewData, UnitType } from "../../models/elearning.model";
import { getTagIcon } from "../../utils/get-tag-icon";
import { ElearningPageCommonsDirective } from "../elearning-page-commons.directive";

export type ExamAnswersFormGroupValue = Partial<{
    [answerId: number]: boolean;
}>;

const WARNING_PROGRESS_PERCENTAGE = 25;
const COUNTDOWN_INTERVAL_MILLISECONDS = 100;

export const isFinishedByTimeoutNavigationStateParam = "isFinishedByTimeout";

@UntilDestroy()
@Component({
    templateUrl: "./exam-page.component.html",
    styleUrls: ["../common-page.styles.scss", "./exam-page.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    hostDirectives: [ElearningPageCommonsDirective],
})
export class ExamPageComponent {
    protected readonly UnitType = UnitType;
    protected readonly QuestionAnswerType = QuestionAnswerType;
    protected readonly WARNING_PROGRESS_PERCENTAGE = WARNING_PROGRESS_PERCENTAGE;
    protected readonly MILLISECONDS_IN_HOUR = MILLISECONDS_IN_HOUR;
    protected readonly getTagIcon = getTagIcon;

    protected readonly viewData$ = this.route.data.pipe(map(({ viewData }) => viewData as ExamPageViewData));
    protected readonly isLoading$ = this.elearningPageCommons.isLoading$;
    protected readonly countdownMilliseconds$ = this.getCountdownMillisecondsObservable();
    protected readonly countdownProgress$ = combineLatest([this.viewData$, this.countdownMilliseconds$]).pipe(
        map(([viewData, countdownMilliseconds]) =>
            Number(
                (viewData.type === UnitType.ExamQuestion
                    ? (countdownMilliseconds / MILLISECONDS_IN_SECOND / viewData.initialTimeSeconds) * 100
                    : 100
                ).toFixed(1)
            )
        ),
        distinctUntilChanged()
    );
    protected readonly examFormGroup$ = this.createExamFormGroup();

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly elearningPageCommons: ElearningPageCommonsDirective
    ) {
        this.countdownMilliseconds$
            .pipe(
                untilDestroyed(this),
                first((countdownMilliseconds) => countdownMilliseconds <= 0)
            )
            .subscribe(() => {
                const viewData = this.route.snapshot.data.viewData as ExamPageViewData;

                if (viewData.type === UnitType.ExamQuestion && viewData.finishLink) {
                    this.router.navigate([viewData.finishLink], {
                        state: { [isFinishedByTimeoutNavigationStateParam]: true },
                        onSameUrlNavigation: "reload",
                        skipLocationChange: true,
                    });
                }
            });
    }

    private static answersGroupValidator(control: AbstractControl): ValidationErrors | null {
        const value = control.value as ExamAnswersFormGroupValue;

        return Object.values(value).every((answer) => answer !== true) ? { required: true } : null;
    }

    protected sendAnswer(link: string | undefined, answers: ExamAnswersFormGroupValue | undefined) {
        if (!link || !answers) {
            throw new Error("Exam answers link is not defined");
        }

        this.router.navigate([link], { state: answers, onSameUrlNavigation: "reload", skipLocationChange: true });
    }

    protected getCountdownMillisecondsObservable(): Observable<number> {
        return this.viewData$.pipe(
            switchMap((viewData) => {
                if (viewData.type !== UnitType.ExamQuestion) {
                    return [];
                }

                return interval(COUNTDOWN_INTERVAL_MILLISECONDS).pipe(map(() => Math.max(0, viewData.timeLeftTimestamp - Date.now())));
            }),
            shareReplay({ refCount: true, bufferSize: 1 })
        );
    }

    private createExamFormGroup() {
        return this.viewData$.pipe(
            map((viewData) => {
                if (!viewData.question) {
                    return undefined;
                }

                return new FormGroup(
                    {
                        ...Object.fromEntries(
                            viewData.question.answers.map((answer) => [answer.id, new FormControl(false, { nonNullable: true })])
                        ),
                    },
                    { validators: [ExamPageComponent.answersGroupValidator] }
                );
            })
        );
    }
}
