<div
    *ngrxLet="{
        summaryViewData: viewData$
    } as vm"
>
    <article class="summary-content" *ngIf="vm.summaryViewData">
        <header class="header" *ngIf="vm.summaryViewData.courseName">
            <div class="header-text">
                <h2>{{ vm.summaryViewData.courseName }}</h2>
            </div>
            <div class="header-image">
                <img class="icon" alt="" [src]="vm.summaryViewData.tags | invoke : getTagIcon : true" />
            </div>
        </header>

        <section class="content">
            <header class="result-header">
                <p class="result-header-text">
                    {{ "eLearningLib.summaryPage.header" | transloco }}
                </p>
                <div class="result-header-content" [innerHTML]="vm.summaryViewData.textHtml | eLearningBypassSanitizer"></div>
                <div class="result-header-image">
                    <img src="assets/images/theory-tag.svg" alt="" />
                </div>
            </header>

            <button type="button" class="button-primary" [routerLink]="vm.summaryViewData.nextLink">
                {{ "eLearningLib.summaryPage.backToCatalogButtonLabel" | transloco }}
            </button>
        </section>
    </article>
</div>
