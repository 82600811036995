import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { DateUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { combineLatest } from "rxjs";
import { map } from "rxjs/operators";

interface TimeRangeDisplayComponentState {
    firstDate: Date | undefined;
    secondDate: Date | undefined;
    shouldShowAsDaysBetween: boolean;
}

@Component({
    selector: "dtm-ui-time-diff-display",
    templateUrl: "./time-diff-display.component.html",
    styles: ["span:not(:last-child)::after { display: inline;  content: ' '; }"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class TimeDiffDisplayComponent {
    @Input({ required: true }) public set firstDate(value: Date | string) {
        this.localStore.patchState({ firstDate: new Date(value) });
    }
    @Input({ required: true }) public set secondDate(value: Date | string) {
        this.localStore.patchState({ secondDate: new Date(value) });
    }
    @Input() public set shouldShowAsDaysBetween(value: BooleanInput) {
        this.localStore.patchState({ shouldShowAsDaysBetween: coerceBooleanProperty(value) });
    }

    protected readonly startDate$ = this.localStore.selectByKey("firstDate").pipe(RxjsUtils.filterFalsy());
    protected readonly endDate$ = this.localStore.selectByKey("secondDate").pipe(RxjsUtils.filterFalsy());
    protected readonly timeDiffData$ = combineLatest([this.startDate$, this.endDate$]).pipe(
        map(([firstDate, secondDate]) => DateUtils.calculateDiffDate(firstDate, secondDate))
    );
    protected readonly shouldShowAsDaysBetween$ = this.localStore.selectByKey("shouldShowAsDaysBetween");
    protected readonly asDaysBetween$ = combineLatest([this.startDate$, this.endDate$]).pipe(
        map(([firstDate, secondDate]) => this.getDaysBetween(firstDate, secondDate))
    );

    constructor(private readonly localStore: LocalComponentStore<TimeRangeDisplayComponentState>) {
        this.localStore.setState({
            firstDate: undefined,
            secondDate: undefined,
            shouldShowAsDaysBetween: false,
        });
    }

    protected getDaysBetween(startDate: Date, endDate: Date): number {
        const daysBetween = DateUtils.daysBetween(startDate, endDate);

        return Math.max(daysBetween + 1, 1);
    }
}
