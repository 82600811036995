import { ViewportScroller } from "@angular/common";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { ActivationStart, Router } from "@angular/router";
import { RouteData, RouteType } from "@dtm-frontend/elearning-client-lib";
import { AuthActions, AuthState } from "@dtm-frontend/shared/auth";
import { DeviceSize, DeviceSizeService, UIActions, UIState } from "@dtm-frontend/shared/ui";
import { LANGUAGE_CONFIGURATION, LanguageCode } from "@dtm-frontend/shared/ui/i18n";
import { UntilDestroy } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { combineLatest } from "rxjs";
import { filter, map } from "rxjs/operators";

@UntilDestroy()
@Component({
    selector: "elearning-header",
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
    public readonly languages = LANGUAGE_CONFIGURATION.languageDefinitions;
    public readonly activeLanguage$ = this.store.select(UIState.activeLanguage);
    public readonly isLoggedIn$ = this.store.select(AuthState.isLoggedIn);
    public readonly userName$ = combineLatest([this.store.select(AuthState.userFirstName), this.store.select(AuthState.userLastName)]).pipe(
        map(([firstName, lastName]) => `${firstName} ${lastName}`)
    );
    protected readonly isMenuClosed$ = this.store.select(UIState.isMenuCollapsed);
    protected readonly isMobile$ = this.deviceSizeService.getSizeObservable(DeviceSize.Smartphone, DeviceSize.SmartphoneWide);

    public readonly isCatalogPage$ = this.router.events.pipe(
        filter((event) => event instanceof ActivationStart),
        map((event: unknown) => ((event as ActivationStart).snapshot.data as RouteData).routeType === RouteType.Catalog)
    );

    constructor(
        private readonly router: Router,
        private readonly store: Store,
        private readonly viewportScroller: ViewportScroller,
        private readonly deviceSizeService: DeviceSizeService
    ) {}

    public logout() {
        this.store.dispatch(new AuthActions.Logout());
    }

    public login() {
        this.store.dispatch(new AuthActions.GoToLoginPage());
    }

    public setActiveLanguage(newLanguageCode: LanguageCode) {
        this.store.dispatch(new UIActions.SetActiveLanguage(newLanguageCode));
    }

    public performSearch(query: string) {
        alert(`search not implemented: ${query}`);
    }

    public scrollToCoursesSection() {
        this.viewportScroller.scrollToAnchor("course-sections-start");
    }

    protected openMenu() {
        this.store.dispatch(new UIActions.SetMenuCollapsedState(false));
    }

    protected closeMenu() {
        this.store.dispatch(new UIActions.SetMenuCollapsedState(true));
    }
}
