<ng-container *ngrxLet="{ isCatalogPage: isCatalogPage$, isMenuClosed: isMenuClosed$, isMobile: isMobile$ } as vm">
    <div *ngIf="vm.isCatalogPage" class="right-blank"></div>
    <div class="header-container" [class.on-catalog-page]="vm.isCatalogPage" [attr.inert]="(vm.isMobile && !vm.isMenuClosed) || null">
        <div class="header-top-container">
            <button
                type="button"
                class="button-icon menu-button"
                (click)="openMenu()"
                [attr.aria-label]="'eLearningLib.mobileMenuStateAriaLabel' | transloco : { isMenuExpanded: false }"
                [attr.aria-expanded]="!vm.isMenuClosed"
            >
                <span class="iconfont icon-menu"></span>
            </button>
            <a routerLink="/" class="logo" [attr.aria-label]="'eLearningLib.logoLinkAriaLabel' | transloco">
                <img src="assets/images/logo.svg" [alt]="'eLearningLib.logoLinkAltText' | transloco" />
            </a>
            <nav class="links" *ngIf="!vm.isCatalogPage && !vm.isMobile">
                <a routerLink="/">{{ "eLearning.header.homeLinkLabel" | transloco }}</a>
            </nav>
            <div class="search">
                <!-- <dtm-ui-input-field>
                    <input
                        [placeholder]="'eLearning.header.searchPlaceholder' | transloco"
                        matInput
                        type="text"
                        #search
                        (keydown.enter)="performSearch(search.value)"
                        autocomplete="off"
                    />
                    <dtm-ui-icon name="search" class="field-prefix"></dtm-ui-icon>
                </dtm-ui-input-field> -->
            </div>
            <div class="user-options">
                <elearning-lib-header-user-button
                    [isUserLoggedIn]="isLoggedIn$ | ngrxPush"
                    [userName]="userName$ | ngrxPush"
                    (logout)="logout()"
                    (login)="login()"
                ></elearning-lib-header-user-button>

                <dtm-ui-language-select
                    class="language-select"
                    [languages]="languages"
                    [selectedLanguage]="activeLanguage$ | ngrxPush"
                    (languageChange)="setActiveLanguage($event)"
                ></dtm-ui-language-select>
            </div>
        </div>

        <div class="header-sub-container" *ngIf="vm.isCatalogPage">
            <div class="splash-text">
                <h1>{{ "eLearning.header.splashText.title" | transloco }}</h1>
                <p>{{ "eLearning.header.splashText.subtitle" | transloco }}</p>
                <button type="button" class="button-primary action-button" (click)="scrollToCoursesSection()">
                    {{ "eLearning.header.splashText.goToCoursesButtonLabel" | transloco }}
                </button>
            </div>
            <div class="splash-image">
                <img src="assets/images/catalog-page/header-splash.svg" width="466" height="480" alt="" />
            </div>
        </div>
    </div>
    <elearning-mobile-menu
        *ngIf="!vm.isMenuClosed"
        [activeLanguage]="activeLanguage$ | ngrxPush"
        (activeLanguageSet)="setActiveLanguage($event)"
        (menuCollapse)="closeMenu()"
    ></elearning-mobile-menu>
</ng-container>
