<div *ngrxLet="{ courseViewData: viewData$, isLoading: isLoading$ } as vm">
    <article class="course-introduction">
        <header class="header">
            <div class="header-text">
                <a class="link" [routerLink]="vm.courseViewData.previousLink" *ngIf="vm.courseViewData.previousLink">
                    <dtm-ui-icon name="arrow-go-back"></dtm-ui-icon>
                    {{ "eLearningLib.courseDetailsPage.backButtonLabel" | transloco }}
                </a>
                <h2>{{ vm.courseViewData.name }}</h2>
            </div>
            <div class="header-image">
                <img class="icon" alt="" [src]="vm.courseViewData.tags | invoke : getTagIcon : true" />
            </div>
        </header>
        <section class="content">
            <h3>{{ vm.courseViewData.description.header }}</h3>
            <p [innerHTML]="vm.courseViewData.description.textHtml | eLearningBypassSanitizer"></p>
            <div *ngIf="vm.courseViewData.requirements && vm.courseViewData.requirements.length > 0" class="required-courses">
                <span>{{ "eLearningLib.courseDetailsPage.requiredCoursesMessage" | transloco }}</span>
                <elearning-lib-course-cards-carousel (goToCourse)="goToDifferentCourse($event)" [cards]="vm.courseViewData.requirements">
                </elearning-lib-course-cards-carousel>
            </div>
            <ng-container *ngIf="vm.courseViewData.progress; else enrollCourseTemplate">
                <button
                    type="button"
                    class="button-primary"
                    [routerLink]="vm.courseViewData.nextLink"
                    [disabled]="vm.isLoading"
                    *ngIf="!vm.courseViewData.requirements || vm.courseViewData.requirements.length === 0"
                >
                    {{
                        "eLearningLib.courseDetailsPage.continueButtonLabel"
                            | transloco : { isCourse: vm.courseViewData.tags | invoke : isCourse }
                    }}
                </button>
            </ng-container>
            <ng-template #enrollCourseTemplate>
                <button
                    type="button"
                    class="button-primary"
                    (click)="enroll(vm.courseViewData.nextLink, vm.courseViewData.courseId)"
                    [disabled]="vm.isLoading"
                    *ngIf="!vm.courseViewData.requirements || vm.courseViewData.requirements.length === 0"
                >
                    {{
                        "eLearningLib.courseDetailsPage.startButtonLabel"
                            | transloco : { isCourse: vm.courseViewData.tags | invoke : isCourse }
                    }}
                </button>
            </ng-template>
        </section>
    </article>
</div>
