import { Directive, ElementRef } from "@angular/core";
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from "@angular/router";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

interface ElearningPageCommonsDirectiveState {
    isLoading: boolean;
}

@UntilDestroy()
@Directive({ standalone: true, providers: [LocalComponentStore] })
export class ElearningPageCommonsDirective {
    public readonly isLoading$ = this.localStore.selectByKey("isLoading");

    constructor(
        private readonly router: Router,
        private readonly localStore: LocalComponentStore<ElearningPageCommonsDirectiveState>,
        private readonly hostElement: ElementRef<HTMLElement>
    ) {
        this.localStore.setState({ isLoading: false });

        this.router.events.pipe(untilDestroyed(this)).subscribe((event) => {
            if (event instanceof NavigationStart) {
                this.localStore.patchState({ isLoading: true });
            } else if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
                this.localStore.patchState({ isLoading: false });
                this.hostElement.nativeElement.scrollIntoView();
            }
        });
    }
}
