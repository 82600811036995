<ng-container *ngIf="shouldShowAsDaysBetween$ | ngrxPush; else timeDiffTemplate">
    <span>{{ "dtmUi.timeLabels.days" | transloco : { days: asDaysBetween$ | ngrxPush } }}</span>
</ng-container>
<ng-template #timeDiffTemplate>
    <ng-container *ngrxLet="timeDiffData$ as timeRangeData">
        <span *ngIf="timeRangeData.days">{{ "dtmUi.timeLabels.days" | transloco : { days: timeRangeData.days } }}</span>
        <span *ngIf="timeRangeData.hours">{{ "dtmUi.timeLabels.hours" | transloco : { hours: timeRangeData.hours } }}</span>
        <span *ngIf="timeRangeData.minutes">{{ "dtmUi.timeLabels.minutes" | transloco : { minutes: timeRangeData.minutes } }}</span>
    </ng-container>
</ng-template>
