<button
    type="button"
    class="button-icon close-button"
    (click)="menuCollapse.emit()"
    [attr.aria-label]="'eLearningLib.mobileMenuStateAriaLabel' | transloco : { isMenuExpanded: true }"
>
    <dtm-ui-icon name="close"></dtm-ui-icon>
</button>

<ng-template #menuItemTemplate let-label="label" let-icon="icon" let-link="link" let-fragment="fragment">
    <a
        [routerLink]="link"
        routerLinkActive="active-route"
        [fragment]="fragment"
        (click)="menuCollapse.emit()"
        [routerLinkActiveOptions]="linkActiveOptions"
    >
        <dtm-ui-icon *ngIf="icon" [name]="icon"></dtm-ui-icon>
        <span class="label">{{ label }}</span>
    </a>
</ng-template>

<ul class="menu-list">
    <li>
        <ng-template
            [ngTemplateOutlet]="menuItemTemplate"
            [ngTemplateOutletContext]="{
                label: 'eLearning.header.homeLinkLabel' | transloco,
                link: '/'
            }"
        ></ng-template>
    </li>
    <li>
        <dtm-ui-language-select
            [languages]="languages"
            [selectedLanguage]="activeLanguage$ | ngrxPush"
            (languageChange)="activeLanguageSet.emit($event)"
        >
        </dtm-ui-language-select>
    </li>
</ul>
