import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input, Optional } from "@angular/core";
import { ControlContainer, FormGroup } from "@angular/forms";
import { LocalComponentStore, ValueAccessorDirective } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { combineLatest } from "rxjs";
import { map } from "rxjs/operators";

export enum QuestionAnswerType {
    Radio = "radio",
    Checkbox = "checkbox",
}

interface QuestionAnswerComponentState {
    isCorrect: boolean | undefined;
    isReadonly: boolean;
    type: QuestionAnswerType;
}

@UntilDestroy()
@Component({
    selector: "elearning-lib-question-answer[type]",
    templateUrl: "./question-answer.component.html",
    styleUrls: ["./question-answer.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
    hostDirectives: [ValueAccessorDirective],
})
export class QuestionAnswerComponent {
    protected readonly isSelected$ = this.valueAccessor.value$;
    protected readonly isCorrect$ = this.localStore.selectByKey("isCorrect");
    protected readonly isRadio$ = this.localStore.selectByKey("type").pipe(map((type) => type === QuestionAnswerType.Radio));
    protected readonly isReadonly$ = this.localStore.selectByKey("isReadonly");
    protected readonly icon$ = this.getIconObservable();

    @Input() public set isCorrect(value: BooleanInput) {
        this.localStore.patchState({ isCorrect: value === undefined ? undefined : coerceBooleanProperty(value) });
    }

    @Input() public set type(value: QuestionAnswerType) {
        this.localStore.patchState({ type: value });
    }

    @Input() public set isReadonly(value: BooleanInput) {
        this.localStore.patchState({ isReadonly: coerceBooleanProperty(value) });
    }

    constructor(
        private readonly localStore: LocalComponentStore<QuestionAnswerComponentState>,
        private readonly valueAccessor: ValueAccessorDirective<boolean>,
        @Optional() private readonly controlContainer: ControlContainer
    ) {
        if (!this.controlContainer) {
            throw new Error("QuestionAnswerComponent must be used inside a form context");
        }

        this.localStore.setState({
            isCorrect: undefined,
            type: QuestionAnswerType.Radio,
            isReadonly: false,
        });
    }

    protected toggleSelection() {
        const newIsSelectedValue = !this.valueAccessor.getValue();

        if (
            newIsSelectedValue &&
            this.localStore.selectSnapshotByKey("type") === QuestionAnswerType.Radio &&
            this.controlContainer.control instanceof FormGroup
        ) {
            Object.values(this.controlContainer.control.controls).forEach((control) => control.value && control.setValue(false));
        }
        this.valueAccessor.writeValue(newIsSelectedValue);
    }

    private getIconObservable() {
        return combineLatest([this.isSelected$, this.isCorrect$, this.isRadio$]).pipe(
            map(([isSelected, isCorrect, isRadio]) => {
                if (isSelected) {
                    if (isCorrect === false) {
                        return isRadio ? "close-circle" : "checkbox-x";
                    } else if (isCorrect === undefined) {
                        return isRadio ? "radio-button" : "checkbox";
                    }

                    return isRadio ? "checkbox-circle" : "checkbox";
                }

                return isRadio ? "radio-button-blank" : "checkbox-blank";
            })
        );
    }
}
