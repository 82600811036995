<div
    class="card"
    *ngIf="course$ | ngrxPush as course"
    [ngClass]="'category-' + course.category.type"
    [tabIndex]="0"
    [attr.aria-label]="'eLearningLib.courseCard.ariaLabel' | transloco"
>
    <div class="card-header">
        <ng-container *ngIf="course.type === CourseDataType.UserCourseDetails" [ngSwitch]="course.status">
            <span *ngSwitchCase="CourseStatus.Passed" class="chip success">
                <dtm-ui-icon name="checkbox-circle" class="status-icon"></dtm-ui-icon>
                <span class="status-label">{{ "eLearningLib.courseCard.passedStatusLabel" | transloco }}</span>
            </span>
            <span *ngSwitchCase="CourseStatus.Failed" class="chip error">
                <dtm-ui-icon name="close-circle" class="status-icon"></dtm-ui-icon>
                <span class="status-label">{{ "eLearningLib.courseCard.failedStatusLabel" | transloco }}</span>
            </span>
            <span *ngSwitchCase="CourseStatus.DuringTheory" class="chip tertiary">
                <dtm-ui-icon name="time" class="status-icon"></dtm-ui-icon>
                <span class="status-label">{{ "eLearningLib.courseCard.inProgressStatusLabel" | transloco }}</span>
            </span>
            <span *ngSwitchCase="CourseStatus.DuringExam" class="chip warning">
                <dtm-ui-icon name="student" class="status-icon"></dtm-ui-icon>
                <span class="status-label">{{ "eLearningLib.courseCard.inExamStatusLabel" | transloco }}</span>
            </span>
        </ng-container>
        <time
            *ngIf="course.type === CourseDataType.UserCourseDetails && course.finishedAt"
            [attr.datetime]="course.finishedAt | invoke : convertToISOString"
            >{{ course.finishedAt | localizeDate : { dateStyle: "medium", timeStyle: "short" } }}</time
        >
    </div>

    <img class="icon" alt="" [src]="course.category.tags | invoke : getTagIcon : false" />

    <span class="course-type">
        {{ course.category.name }}
    </span>

    <h4 class="course-header">
        {{ course.header }}
    </h4>

    <ng-container *ngIf="course.type === CourseDataType.CourseDetails; else progressTemplate">
        <p class="course-description">{{ course.description }}</p>
        <button type="button" class="button-primary" (click)="goToCourse.emit(course)">
            {{ "eLearningLib.courseCard.goToCourseOrExamButtonLabel" | transloco : { isCourse: course.category.tags | invoke : isCourse } }}
        </button>
    </ng-container>
    <ng-template #progressTemplate>
        <ng-container *ngIf="course.type === CourseDataType.UserCourseDetails && course.progress">
            <span class="progress-label">
                {{
                    "eLearningLib.courseCard.progressLabel" | transloco : { value: course.progress.completionPercentage | number : "1.0-0" }
                }}
            </span>
            <mat-progress-bar mode="determinate" [value]="course.progress.completionPercentage"></mat-progress-bar>

            <button type="button" class="button-primary" (click)="goToCourse.emit(course)" *ngIf="course.status !== CourseStatus.Passed">
                <ng-container *ngIf="course.category.tags | invoke : isCourse; else goToExamButtonTemplate">
                    {{ "eLearningLib.courseCard.goToCourseButtonLabel" | transloco : { status: course.status } }}
                </ng-container>
                <ng-template #goToExamButtonTemplate>
                    {{ "eLearningLib.courseCard.goToExamButtonLabel" | transloco : { status: course.status } }}
                </ng-template>
            </button>
        </ng-container>
    </ng-template>
</div>
