<ng-container *ngrxLet="{ hasPassed: hasPassed$, percentage: percentage$ } as vm">
    <img
        class="result-icon"
        alt=""
        [src]="vm.hasPassed ? 'assets/images/quiz-result-passed.svg' : 'assets/images/quiz-result-failed.svg'"
    />
    <section class="result-content" [class.passed]="vm.hasPassed" [class.failed]="!vm.hasPassed">
        <span class="result-percentage-label">{{ "eLearningLib.quizResultCard.scoreLabel" | transloco }}</span>
        <span class="result-percentage">{{ "eLearningLib.quizResultCard.percentageLabel" | transloco : { value: vm.percentage } }}</span>
        <span class="result-text-message-label">{{
            (vm.hasPassed ? "eLearningLib.quizResultCard.passedLabel" : "eLearningLib.quizResultCard.failedLabel") | transloco
        }}</span>
    </section>
    <img class="result-shape-background" alt="" src="assets/images/quiz-result-background-shape.svg" />
</ng-container>
