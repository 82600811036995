<ng-container *ngrxLet="{ isMenuClosed: isMenuClosed$, isMobile: isMobile$ } as vm">
    <div class="sidebar-overlay" (click)="closeSidebar()" *ngIf="!vm.isMenuClosed"></div>
    <header class="header">
        <elearning-header></elearning-header>
    </header>
    <main class="content" dtmUiDisplayVersion [attr.inert]="(vm.isMobile && !vm.isMenuClosed) || null">
        <div class="content-container">
            <router-outlet></router-outlet>
            <button
                type="button"
                [class.show]="isScrollToTopButtonVisible$ | ngrxPush"
                class="button-icon scroll-top"
                (click)="scrollToTop()"
                [attr.aria-label]="'eLearningLib.pageScrollTopAriaLabel' | transloco"
            >
                <dtm-ui-icon name="arrow-up"></dtm-ui-icon>
            </button>
        </div>
    </main>
    <dtm-ui-cookies-popup *ngIf="isCookiesInfoVisible$ | ngrxPush" (cookiesHide)="hideCookiesInfo()"></dtm-ui-cookies-popup>
    <dtm-ui-webpage-footer
        [activeLanguage]="activeLanguage$ | ngrxPush"
        (cookiesInfoSelect)="showCookiesInfo()"
        [attr.inert]="!vm.isMenuClosed || null"
    ></dtm-ui-webpage-footer>
</ng-container>
