<form [formGroup]="formGroup">
    <dtm-ui-quill-editor [formControl]="htmlFormControl" [isQuillToolbarExtended]="true"> </dtm-ui-quill-editor>
    <div>
        <button (click)="addRow()" class="table-button" type="button">{{ "dtmUi.extendedQuillEditor.addRow" | transloco }}</button>
        <button (click)="addColumn()" class="table-button" type="button">{{ "dtmUi.extendedQuillEditor.addColumn" | transloco }}</button>
        <button (click)="removeLastRow()" class="table-button" type="button">
            {{ "dtmUi.extendedQuillEditor.removeLastRow" | transloco }}
        </button>
        <button (click)="removeLastColumn()" class="table-button" type="button">
            {{ "dtmUi.extendedQuillEditor.removeLastColumn" | transloco }}
        </button>
        <table #table class="quill-table">
            <tr>
                <th *ngFor="let header of tableHeaders$ | ngrxPush" contenteditable="true"></th>
            </tr>
            <tr *ngFor="let row of tableData$ | ngrxPush">
                <td *ngFor="let cell of row" contenteditable="true"></td>
            </tr>
        </table>
    </div>
</form>
