import { ChangeDetectionStrategy, Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UntilDestroy } from "@ngneat/until-destroy";
import { map } from "rxjs";
import { CourseData, CourseDetailsPageViewData, UnitType } from "../../models/elearning.model";
import { CourseTag } from "../../services/elearning-api.service.converters";
import { getTagIcon } from "../../utils/get-tag-icon";
import { ElearningPageCommonsDirective } from "../elearning-page-commons.directive";

export const courseIdQueryParam = "courseId";

@UntilDestroy()
@Component({
    templateUrl: "./course-details-page.component.html",
    styleUrls: ["../common-page.styles.scss", "./course-details-page.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    hostDirectives: [ElearningPageCommonsDirective],
})
export class CourseDetailsPageComponent {
    protected readonly UnitType = UnitType;

    protected readonly viewData$ = this.route.data.pipe(map(({ viewData }) => viewData as CourseDetailsPageViewData));
    protected readonly isLoading$ = this.elearningPageCommons.isLoading$;
    protected readonly getTagIcon = getTagIcon;

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly elearningPageCommons: ElearningPageCommonsDirective
    ) {}

    protected enroll(link: string | undefined, courseId: string) {
        if (!link) {
            throw new Error("Enrollment link is not defined");
        }

        if (courseId) {
            this.router.navigate([link], {
                queryParams: { [courseIdQueryParam]: courseId, name: this.route.snapshot.data.viewData.name },
            });
        }
    }

    protected goToDifferentCourse(course: CourseData) {
        this.router.navigate([course.link]);
    }

    protected isCourse(tags: CourseTag[]) {
        return !tags.length || tags.includes(CourseTag.Theory);
    }
}
