import { ChangeDetectionStrategy, Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
    selector: "elearning-lib-course-not-allowed",
    templateUrl: "./course-not-allowed.component.html",
    styleUrls: ["../common-page.styles.scss", "./course-not-allowed.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CourseNotAllowedComponent {
    constructor(protected readonly route: ActivatedRoute) {}
}
