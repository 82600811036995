import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AuthActions, NOT_AUTHORIZED_ROUTE } from "@dtm-frontend/shared/auth";
import { I18nService } from "@dtm-frontend/shared/ui/i18n";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Actions, ofActionDispatched } from "@ngxs/store";
import { skip, tap } from "rxjs/operators";

@UntilDestroy()
@Injectable({
    providedIn: "root",
})
export class ActionsHandler {
    constructor(private readonly actions$: Actions, private readonly router: Router, private readonly i18nService: I18nService) {
        this.handleAuthGoToNotAuthorizedPage();
        this.reloadOnLanguageChange();
    }

    private reloadOnLanguageChange() {
        this.i18nService.currentLanguage$
            .pipe(
                skip(1),
                tap(() => location.reload()),
                untilDestroyed(this)
            )
            .subscribe();
    }

    private handleAuthGoToNotAuthorizedPage() {
        this.actions$
            .pipe(
                ofActionDispatched(AuthActions.GoToNotAuthorizedPage),
                tap(() => this.router.navigate([`/${NOT_AUTHORIZED_ROUTE}`])),
                untilDestroyed(this)
            )
            .subscribe();
    }
}
