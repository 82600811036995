<div class="container">
    <div>
        <p class="hint-text">{{ "dtmUi.adminPanel.addTableText" | transloco }}</p>
        <p class="hint-text">{{ "dtmUi.adminPanel.addDotText" | transloco }}</p>
        <elearning-lib-extended-quill-editor #editor></elearning-lib-extended-quill-editor>
    </div>
    <mat-tab-group [selectedIndex]="selectedTab$ | ngrxPush" (selectedTabChange)="changeTab($event)" dynamicHeight>
        <mat-tab [label]="'dtmUi.adminPanel.showPreview' | transloco"
            ><div class="html-view" [innerHTML]="editor.result | safeHtml"></div>
        </mat-tab>
        <mat-tab [label]="'dtmUi.adminPanel.showHTML' | transloco">
            <code>{{ editor.result }}</code>
        </mat-tab>
    </mat-tab-group>
</div>
