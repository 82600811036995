<ng-container *ngIf="isUserLoggedIn$ | ngrxPush; else loginButton">
    <button type="button" class="panel-button" [matMenuTriggerFor]="userDropdownMenu">
        <span class="user-avatar">
            <span class="no-avatar">
                <dtm-ui-icon name="user"></dtm-ui-icon>
            </span>
        </span>
        <span class="panel-label user-name" [title]="userName" *ngrxLet="userName$ | ngrxPush as userName">{{ userName }}</span>
        <dtm-ui-icon name="arrow-down" class="arrow-icon"></dtm-ui-icon>
    </button>
</ng-container>

<ng-template #loginButton>
    <button type="button" class="panel-button login-button" (click)="login.emit()">
        <span class="panel-label">{{ "eLearningLib.headerUserButton.loginLinkLabel" | transloco }}</span>
    </button>
</ng-template>

<mat-menu #userDropdownMenu="matMenu" xPosition="before">
    <button mat-menu-item type="button" (click)="logout.emit()">
        <dtm-ui-icon name="logout-circle"></dtm-ui-icon>
        <span>{{ "eLearningLib.headerUserButton.logoutMenuItemLabel" | transloco }}</span>
    </button>
</mat-menu>
