import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";

interface QuizResultCardComponentState {
    hasPassed: boolean;
    percentage: number;
}

@UntilDestroy()
@Component({
    selector: "elearning-lib-quiz-result[hasPassed][percentage]",
    templateUrl: "./quiz-result-card.component.html",
    styleUrls: ["./quiz-result-card.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class QuizResultCardComponent {
    protected readonly hasPassed$ = this.localStore.selectByKey("hasPassed");
    protected readonly percentage$ = this.localStore.selectByKey("percentage");

    @Input() public set hasPassed(value: BooleanInput) {
        this.localStore.patchState({ hasPassed: coerceBooleanProperty(value) });
    }

    @Input() public set percentage(value: number) {
        this.localStore.patchState({ percentage: value });
    }

    constructor(private readonly localStore: LocalComponentStore<QuizResultCardComponentState>) {
        this.localStore.setState({
            hasPassed: false,
            percentage: 0,
        });
    }
}
