import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { IsActiveMatchOptions } from "@angular/router";
import { LANGUAGE_CONFIGURATION, LanguageCode } from "@dtm-frontend/shared/ui/i18n";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";

interface MobileMenuComponentState {
    activeLanguage: LanguageCode | undefined;
    isCatalogPage: boolean;
}

@Component({
    selector: "elearning-mobile-menu",
    templateUrl: "./mobile-menu.component.html",
    styleUrls: ["./mobile-menu.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MobileMenuComponent {
    @Output() public readonly menuCollapse = new EventEmitter<void>();
    @Output() public readonly activeLanguageSet = new EventEmitter<LanguageCode>();
    @Input() public set activeLanguage(value: LanguageCode | undefined) {
        this.localStore.patchState({ activeLanguage: value });
    }
    @Input() public set isCatalogPage(value: BooleanInput) {
        this.localStore.patchState({ isCatalogPage: coerceBooleanProperty(value) });
    }

    protected readonly linkActiveOptions: IsActiveMatchOptions = {
        matrixParams: "exact",
        queryParams: "exact",
        paths: "exact",
        fragment: "exact",
    };
    protected readonly languages = LANGUAGE_CONFIGURATION.languageDefinitions;

    protected readonly activeLanguage$ = this.localStore.selectByKey("activeLanguage").pipe(RxjsUtils.filterFalsy());
    protected readonly isCatalogPage$ = this.localStore.selectByKey("isCatalogPage");

    constructor(private readonly localStore: LocalComponentStore<MobileMenuComponentState>) {
        localStore.setState({ activeLanguage: undefined, isCatalogPage: false });
    }
}
