import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { DeviceSize, DeviceSizeService } from "@dtm-frontend/shared/ui";
import { ArrayUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { CourseData } from "../../models/elearning.model";

interface CourseCardsCarouselComponentState {
    cards: CourseData[];
}

@UntilDestroy()
@Component({
    selector: "elearning-lib-course-cards-carousel[cards]",
    templateUrl: "./course-cards-carousel.component.html",
    styleUrls: ["./course-cards-carousel.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class CourseCardsCarouselComponent {
    @Input() public set cards(value: CourseData[] | undefined) {
        this.localStore.patchState({ cards: value ?? [] });
    }

    @Output() public readonly goToCourse = new EventEmitter<CourseData>();

    protected readonly slides$ = this.localStore.selectByKey("cards");

    protected isMobile$ = this.deviceSizeService.getSizeObservable(DeviceSize.Smartphone, DeviceSize.SmartphoneWide);
    protected isTablet$ = this.deviceSizeService.getSizeObservable(DeviceSize.Tablet);
    protected isSmallDesktop$ = this.deviceSizeService.getSizeObservable(DeviceSize.Desktop);

    constructor(
        private readonly localStore: LocalComponentStore<CourseCardsCarouselComponentState>,
        private readonly deviceSizeService: DeviceSizeService
    ) {
        this.localStore.setState({
            cards: [],
        });
    }

    protected getPartitionedSlides(slides: CourseData[] | undefined, count = 1): CourseData[][] {
        if (!slides) {
            return [];
        }

        return ArrayUtils.groupByCount(slides, count);
    }
}
