<div *ngrxLet="{ examViewData: viewData$, isLoading: isLoading$, examFormGroup: examFormGroup$ } as vm">
    <article class="exam">
        <header class="header">
            <div class="header-text">
                <h2>{{ "eLearningLib.examPage.header" | transloco : { courseName: vm.examViewData.courseName } }}</h2>
            </div>
            <div class="header-image">
                <img class="icon" alt="" [src]="vm.examViewData.tags | invoke : getTagIcon : true" />
            </div>
        </header>
        <section class="content" *ngIf="vm.examViewData.question && vm.examViewData.type === UnitType.ExamQuestion">
            <header class="question-header">
                <div class="question-number-progress">
                    <p>
                        {{
                            "eLearningLib.examPage.questionNumber"
                                | transloco : { current: vm.examViewData.currentQuestionIndex, total: vm.examViewData.totalQuestionsCount }
                        }}
                    </p>
                    <mat-progress-bar
                        mode="determinate"
                        [value]="(vm.examViewData.currentQuestionIndex / vm.examViewData.totalQuestionsCount) * 100"
                    ></mat-progress-bar>
                </div>
                <div class="question-content" [innerHTML]="vm.examViewData.question.textHtml | eLearningBypassSanitizer"></div>
                <div class="countdown-timer">
                    <svg
                        class="spinner-background"
                        preserveAspectRatio="xMidYMid meet"
                        focusable="false"
                        aria-hidden="true"
                        viewBox="0 0 115.4 115.4"
                    >
                        <circle cx="50%" cy="50%" r="52" class="ng-star-inserted"></circle>
                    </svg>
                    <mat-progress-spinner
                        diameter="114"
                        [class.warning]="countdownProgress < WARNING_PROGRESS_PERCENTAGE"
                        [value]="countdownProgress"
                        *ngrxLet="countdownProgress$ as countdownProgress"
                    ></mat-progress-spinner>
                    <ng-container *ngrxLet="countdownMilliseconds$ as countdownMilliseconds">
                        <span *ngIf="countdownMilliseconds >= MILLISECONDS_IN_HOUR; else shortTimerTemplate" class="long-timer">
                            {{ countdownMilliseconds | date : "hh:mm:ss" : "+0000" }}
                        </span>
                        <ng-template #shortTimerTemplate>
                            <span class="short-timer">{{ countdownMilliseconds | date : "mm:ss" : "+0000" }}</span>
                        </ng-template>
                    </ng-container>
                </div>
            </header>

            <form class="answers" [formGroup]="vm.examFormGroup" *ngIf="vm.examFormGroup">
                <elearning-lib-question-answer
                    *ngFor="let answer of vm.examViewData.question.answers"
                    [type]="vm.examViewData.question.isMultipleChoice ? QuestionAnswerType.Checkbox : QuestionAnswerType.Radio"
                    [formControlName]="answer.id"
                >
                    <span [innerHTML]="answer.textHtml | eLearningBypassSanitizer"></span>
                </elearning-lib-question-answer>
            </form>

            <button
                type="button"
                class="button-primary"
                (click)="sendAnswer(vm.examViewData.nextLink, vm.examFormGroup?.value)"
                [disabled]="vm.isLoading || vm.examFormGroup?.valid !== true"
            >
                {{
                    (vm.examViewData.currentQuestionIndex === vm.examViewData.totalQuestionsCount
                        ? "eLearningLib.examPage.endExamButtonLabel"
                        : "eLearningLib.examPage.nextQuestionButtonLabel"
                    ) | transloco
                }}
                <dtm-ui-icon name="arrow-go"></dtm-ui-icon>
            </button>
        </section>
        <section class="content" *ngIf="vm.examViewData.type === UnitType.Summary">
            <header class="result-header">
                <p class="result-header-text" [ngClass]="{ failed: !vm.examViewData.hasPassed, passed: vm.examViewData.hasPassed }">
                    {{
                        (vm.examViewData.hasPassed ? "eLearningLib.examPage.passedExamHeader" : "eLearningLib.examPage.failedExamHeader")
                            | transloco
                    }}
                </p>
                <div class="result-header-content" [innerHTML]="vm.examViewData.textHtml | eLearningBypassSanitizer"></div>
                <div class="result-header-image">
                    <img [src]="'assets/images/' + (vm.examViewData.hasPassed ? 'exam-passed.svg' : 'exam-failed.svg')" alt="" />
                </div>
            </header>

            <button type="button" class="button-primary" [routerLink]="vm.examViewData.nextLink" [disabled]="vm.isLoading">
                {{ "eLearningLib.examPage.backToCatalogButtonLabel" | transloco }}
            </button>
        </section>
    </article>
</div>
