import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";

interface HeaderUserButtonComponentState {
    isUserLoggedIn: boolean | undefined;
    userName: string | undefined;
}

@Component({
    selector: "elearning-lib-header-user-button[isUserLoggedIn][userName]",
    templateUrl: "./header-user-button.component.html",
    styleUrls: ["./header-user-button.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class HeaderUserButtonComponent {
    @Input() public set isUserLoggedIn(value: boolean | undefined) {
        this.localStore.patchState({ isUserLoggedIn: value });
    }

    @Input() public set userName(value: string | undefined) {
        this.localStore.patchState({ userName: value });
    }

    @Output() public goToUserProfilePage = new EventEmitter();
    @Output() public login = new EventEmitter();
    @Output() public logout = new EventEmitter();

    protected readonly isUserLoggedIn$ = this.localStore.selectByKey("isUserLoggedIn");
    protected readonly userName$ = this.localStore.selectByKey("userName").pipe(RxjsUtils.filterFalsy());

    constructor(private readonly localStore: LocalComponentStore<HeaderUserButtonComponentState>) {
        localStore.setState({ isUserLoggedIn: false, userName: undefined });
    }
}
