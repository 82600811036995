import { ChangeDetectionStrategy, Component, forwardRef, Input } from "@angular/core";
import {
    ControlValueAccessor,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    UntypedFormArray,
    UntypedFormControl,
    ValidationErrors,
    Validator,
    Validators,
} from "@angular/forms";
import { FunctionUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

const MAX_NAME_LENGTH = 500;

interface AdditionalCompetenciesComponentState {
    additionalCompetencies: string[];
    label: string | undefined;
}

@UntilDestroy()
@Component({
    selector: "dtm-ui-additional-competencies",
    templateUrl: "./additional-competencies.component.html",
    styleUrls: ["./additional-competencies.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AdditionalCompetenciesComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => AdditionalCompetenciesComponent),
            multi: true,
        },
    ],
})
export class AdditionalCompetenciesComponent implements ControlValueAccessor, Validator {
    protected readonly MAX_NAME_LENGTH = MAX_NAME_LENGTH;

    @Input() public set additionalCompetencies(value: string[] | undefined) {
        value = value ?? [];
        this.localStore.patchState({ additionalCompetencies: value });

        this.initCompetenciesForm(value);
    }
    @Input() public set label(value: string | undefined) {
        this.localStore.patchState({ label: value });
    }

    protected readonly competenciesFormArray = new UntypedFormArray([]);
    protected readonly label$ = this.localStore.selectByKey("label");

    private onValidationChange = FunctionUtils.noop;
    private propagateChange: (value: string[]) => void = FunctionUtils.noop;

    constructor(private readonly localStore: LocalComponentStore<AdditionalCompetenciesComponentState>) {
        this.localStore.setState({
            additionalCompetencies: [],
            label: undefined,
        });

        this.competenciesFormArray.valueChanges.pipe(untilDestroyed(this)).subscribe((competencies: string[]) => {
            if (!this.validate()) {
                this.propagateChange(this.prepareResultAdditionalCompetencies(competencies));
            }

            this.onValidationChange();
        });
    }

    public get competenciesControls() {
        return this.competenciesFormArray.controls as UntypedFormControl[];
    }

    public registerOnChange(fn: (value: string[]) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(): void {}

    public registerOnValidatorChange(fn: () => void): void {
        this.onValidationChange = fn;
    }

    public validate(): ValidationErrors | null {
        if (this.competenciesFormArray.valid) {
            return null;
        }

        return { required: true };
    }

    public writeValue(value: string[]): void {
        this.additionalCompetencies = value ?? [];
    }

    public addCompetency(): void {
        this.competenciesFormArray.push(this.createCompetencyFormControl());
    }

    public removeCompetency(index: number): void {
        this.competenciesFormArray.removeAt(index);
    }

    private prepareResultAdditionalCompetencies(competencies: string[]): string[] {
        return competencies.filter(Boolean);
    }

    private initCompetenciesForm(competencies: string[]): void {
        this.competenciesFormArray.clear({ emitEvent: false });

        if (!competencies.length) {
            competencies.push("");
        }

        for (const competency of competencies) {
            this.competenciesFormArray.push(this.createCompetencyFormControl(competency));
        }
    }

    private createCompetencyFormControl(competency?: string): UntypedFormControl {
        return new UntypedFormControl(competency ?? null, [Validators.maxLength(this.MAX_NAME_LENGTH)]);
    }
}
