import { CourseTag } from "../services/elearning-api.service.converters";

export function getTagIcon(courseTags: CourseTag[] | undefined, isDark: boolean): string {
    if (isDark) {
        return courseTags?.includes(CourseTag.Theory)
            ? "assets/images/theory-tag-dark.svg"
            : courseTags?.includes(CourseTag.Exam)
            ? " assets/images/exam-tag-dark.svg"
            : "assets/images/other-tag-dark.svg";
    }

    return courseTags?.includes(CourseTag.Theory)
        ? "assets/images/theory-tag.svg"
        : courseTags?.includes(CourseTag.Exam)
        ? " assets/images/exam-tag.svg"
        : "assets/images/other-tag.svg";
}
